.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px -13px 20px 3px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  background-color: #fff;
  z-index: 9;
}
.pagination a {
  border: 1px solid #4a5745;
  color: #4a5745;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
}
.pagination a:hover {
  color: #fff !important;
  background-color: #4a5745;
  cursor: pointer;
}
.pagination li.next a, .pagination li.previous a {
  border-color: #4a5745;
  background-image: url(../img/green-arrow.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.pagination li.next a:hover, .pagination li.previous a:hover {
  background-color: #4a5745;
  background-image: url(../img/right-arrow.svg);
}
.pagination li.next.paginationDisabled a:hover, .pagination li.paginationDisabled.previous a:hover {
  background-color: transparent;
  background-image: url(../img/green-arrow.svg);
  cursor: not-allowed;
}
.pagination li.previous {
  transform: scaleX(-1);
}

.paginationLink {
  font-weight: bold;
}
.paginationLink:hover {
  background-color: red;
}

.paginationActive a {
  color: #fff !important;
  border: 1px solid #4a5745;
  background: #4a5745;
}

.paginationDisabled a {
  opacity: 0.6;
}/*# sourceMappingURL=pagination.css.map */