$white: #fff;
$black: #000;
$green: #386224;

.gradientgreen {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#386224+0,639250+100 */
  background: rgb(56, 98, 36); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgba(56, 98, 36, 1) 0%,
    rgba(99, 146, 80, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(56, 98, 36, 1) 0%,
    rgba(99, 146, 80, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(56, 98, 36, 1) 0%,
    rgba(99, 146, 80, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#386224', endColorstr='#639250',GradientType=1 ); /* IE6-9 */
}

.gradientHover {
  background: rgb(56, 98, 36); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgba(56, 98, 36, 1) 0%,
    rgba(230, 204, 103, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(56, 98, 36, 1) 0%,
    rgba(230, 204, 103, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(56, 98, 36, 1) 0%,
    rgba(230, 204, 103, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#386224', endColorstr='#e6cc67',GradientType=1 ); /* IE6-9 */
}
