.createTeamModal .viewTeam {
  width: auto;
  margin-left: 10px;
  border-radius: 4px;
  padding: 12px 20px;
}
.createTeamModal h3 {
  font-size: 28px;
  color: #960396;
  margin-top: 15px;
}
.createTeamModal .wintheMatchSec {
  margin-top: 40px;
}
.createTeamModal .wintheMatchSec h4 {
  font-size: 24px;
  font-weight: 500;
}
.createTeamModal .selectTeam {
  margin-top: 30px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .createTeamModal .selectTeam {
    display: flex;
  }
}
.createTeamModal .selectTeam img {
  margin-right: 10px;
}
@media (max-width: 767px) {
  .createTeamModal .selectTeam img {
    max-width: 30px;
  }
}
.createTeamModal .selectTeam .form-check-inline {
  display: inline-flex;
  align-items: center;
}
.createTeamModal .selectTeam label {
  font-size: 18px;
  font-weight: 600;
  color: #182115;
}
@media (max-width: 767px) {
  .createTeamModal .selectTeam label {
    font-size: 12px;
  }
}
.createTeamModal .selectTeam .form-check-input {
  margin-right: 10px;
  margin-left: 0;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 viewBox=%27-6 -6 12 12%27%3e%3ccircle r=%276%27 fill=%27%999%27/%3e%3c/svg%3e");
}
.createTeamModal .selectTeam .form-check-input:checked {
  background-color: transparent;
  border-color: #3a6426;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 viewBox=%27-6 -6 12 12%27%3e%3ccircle r=%276%27 fill=%27%233a6426%27/%3e%3c/svg%3e");
  background-size: 10px;
}
.createTeamModal .selectTeam .form-check-input:focus {
  box-shadow: none;
}
.createTeamModal .selectTeam .form-check-input:checked ~ label {
  color: #3a6426;
}
.createTeamModal .playerCardTeam {
  border: 1px solid #c9d8c3;
  border-radius: 5px;
  display: flex;
  width: 100%;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .createTeamModal .playerCardTeam {
    flex-direction: column;
  }
}
.createTeamModal .playerCardTeam .imageCol {
  width: 140px;
  border-right: 1px solid #c9d8c3;
}
@media (max-width: 767px) {
  .createTeamModal .playerCardTeam .imageCol {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #c9d8c3;
    text-align: center;
  }
}
.createTeamModal .playerCardTeam .imageCol .form-check {
  margin: 10px 0 0 0px;
}
.createTeamModal .playerCardTeam .imageCol .form-check .form-check-input {
  border-color: #386224;
  background: rgba(99, 146, 80, 0.0980392157);
  margin-left: 0;
}
.createTeamModal .playerCardTeam .imageCol .form-check .form-check-input:checked[type=checkbox] {
  background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e") no-repeat center, linear-gradient(180deg, #639250 0%, #386224 100%);
  background-size: 12px, cover;
}
.createTeamModal .playerCardTeam .detailsCol {
  width: calc(100% - 140px);
  padding: 10px;
}
@media (max-width: 767px) {
  .createTeamModal .playerCardTeam .detailsCol {
    width: 100%;
  }
}
.createTeamModal .playerCardTeam .detailsCol .playerName {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #386224;
}
.createTeamModal .playerCardTeam .detailsCol .playerDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-top: 20px;
}
.createTeamModal .playerCardTeam .detailsCol .playerDetails p {
  font-size: 12px;
  color: #272727;
}
.createTeamModal .playerCardTeam .detailsCol .playerDetails span {
  display: block;
  text-align: center;
  color: #272727;
  font-size: 16px;
  font-weight: bold;
}
.createTeamModal .playerCardTeam .detailsCol .playerDetails .totalRaids {
  border-right: 1px solid rgba(126, 126, 126, 0.5);
  width: 26%;
  padding: 5px;
}
.createTeamModal .playerCardTeam .detailsCol .playerDetails .successfulRaids {
  border-right: 1px solid rgba(126, 126, 126, 0.5);
  width: 41%;
}
.createTeamModal .playerCardTeam .detailsCol .playerDetails .superRaids {
  width: 33%;
}
.createTeamModal .playerCardTeam .detailsCol .playerRole {
  padding-top: 20px;
}
.createTeamModal .playerCardTeam .detailsCol .playerRole .role {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 50%;
  background: transparent linear-gradient(180deg, #639250 0%, #386224 100%) 0% 0% no-repeat padding-box;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
.createTeamModal .playerCardTeam .detailsCol .playerRole .role.selected {
  background: #aa3e97;
}
.createTeamModal .playerCardTeam.playerPoints .detailsCol {
  padding: 20px;
}
.createTeamModal .playerCardTeam.playerPoints .detailsCol .playerName {
  text-align: left;
}
.createTeamModal .playerCardTeam.playerPoints .detailsCol .playerDetails {
  display: flex;
  width: 100%;
  text-align: left;
  justify-content: space-between;
  align-items: center;
}
.createTeamModal .playerCardTeam.playerPoints .detailsCol .playerDetails .playerRole {
  padding-top: 0;
}
.createTeamModal .playerCardTeam.playerPoints .detailsCol .playerDetails .superRaids {
  width: auto;
}

.createteamWraper {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid #bedeaf;
  border-radius: 20px;
  padding: 50px 30px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .createteamWraper {
    padding: 30px 15px;
  }
}
.createteamWraper .filterSec {
  position: inherit;
}
@media (max-width: 767px) {
  .createteamWraper .filterSec {
    flex-wrap: wrap;
    margin-top: 10px;
    justify-content: center;
  }
  .createteamWraper .filterSec .viewTeam {
    margin-top: 10px;
  }
}/*# sourceMappingURL=style.css.map */