.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px -13px 20px 3px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  background-color: #fff;
  z-index: 9;

  & a {
    border: 1px solid #4a5745;
    color: #4a5745;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 4px;
    &:hover {
      color: #fff !important;
      background-color: #4a5745;
      cursor: pointer;
    }
  }

  li {
    &.next {
      a {
        border-color: #4a5745;
        background-image: url(../img/green-arrow.svg);
        background-repeat: no-repeat;
        background-position: center;
        &:hover {
          background-color: #4a5745;
          background-image: url(../img/right-arrow.svg);
        }
      }

      &.paginationDisabled {
        a {
          &:hover {
            background-color: transparent;
            background-image: url(../img/green-arrow.svg);
            cursor: not-allowed;
          }
        }
      }
    }
    &.previous {
      transform: scaleX(-1);
      @extend .next;
    }
  }
}

.paginationLink {
  font-weight: bold;
  &:hover {
    background-color: red;
  }
}

.paginationActive {
  & a {
    color: #fff !important;
    border: 1px solid #4a5745;
    background: #4a5745;
  }
}

.paginationDisabled {
  & a {
    opacity: 0.6;
  }
}
