.hideScrollbar, .footer nav {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hideScrollbar::-webkit-scrollbar, .footer nav::-webkit-scrollbar {
  display: none;
}

.modal-dialog {
  display: flex;
  align-items: flex-start;
  background: #fff;
}
.modal-dialog .modal-content {
  background-color: #fff;
}
.modal-dialog .modal-content .modal-body::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}
.modal-dialog .modal-content .modal-body::-webkit-scrollbar-thumb {
  background-color: #e9ede7;
}
.modal-dialog.login {
  align-items: center;
  background: transparent;
}

.scroll {
  min-height: 64px !important;
}
@media (max-width: 990px) {
  .scroll {
    justify-content: flex-start !important;
  }
}
.scroll::-webkit-scrollbar {
  height: 6px;
  width: 12px;
  background-color: transparent;
}
.scroll::-webkit-scrollbar-thumb {
  background-color: #e9ede7;
}

.footer {
  transition: transform ease 1s;
}
.footer .footer-collapse {
  position: absolute;
  top: -32px;
  background: #53834d url(../img/right-arrow.svg) center/8px no-repeat;
  transform: rotate(90deg);
  width: 38px;
  height: 28px;
  border-radius: 20px 0 0 20px;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 0;
  border: none;
}
.footer .footer-collapse.rotate {
  transform: rotate(-90deg);
  border-radius: 0 20px 20px 0;
}
.footer .slider-btn {
  position: absolute;
  width: 32px;
  background: rgba(0, 0, 0, 0.5) url(../img/right-arrow.svg) center/12px no-repeat;
  opacity: 0.8;
  top: 0;
  bottom: 0;
  border: none;
  z-index: 2;
}
.footer .slider-btn.left {
  transform: scaleX(-1);
  left: 0;
}
.footer .slider-btn.right {
  right: 0;
}
.footer .slider-btn:hover {
  cursor: pointer;
  opacity: 1;
}
.footer nav {
  position: relative;
  overflow: auto hidden;
}
.footer nav ul {
  min-width: 1183px;
}

.table .list-icon {
  max-width: 36px;
}

.ReactModal__Overlay--after-open {
  z-index: 9999 !important;
}

.row .season-list-item img {
  cursor: pointer;
}

.my-loading {
  height: 100%;
  width: 100%;
  min-height: 50vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
.my-loading div {
  width: 138px;
  display: flex;
}
.my-loading div span {
  width: 32px;
  height: 32px;
  margin-right: 3px;
  background-color: rgb(56, 98, 36);
}
.my-loading div span:nth-child(even) {
  background-color: #960396;
}
.my-loading div span:last-child {
  margin: 0;
}

.jc-fs {
  justify-content: flex-start !important;
}

.jc-c {
  justify-content: center;
}

.dataDetails.player-data {
  background: url(../img/black-pattern.jpg) no-repeat;
  background-size: cover;
}
.dataDetails.player-data .attackingData {
  color: #fff !important;
}
.dataDetails.player-data .attackingData div {
  color: #1da21e;
}

.playerStatsHeader .titleText {
  color: #1da21e !important;
}

.playerImageSec .playerName {
  color: #1da21e !important;
}

.mapSec {
  height: 80vh;
}
.mapSec iframe {
  height: 100%;
}

.thumbOuter {
  flex-direction: column;
}
.thumbOuter iframe,
.thumbOuter .video {
  background: #000;
  border-radius: 15px;
  max-height: 340px;
  width: 100%;
}
.thumbOuter .video-title {
  display: flex;
  width: 100%;
  font-weight: 500;
  position: absolute;
  top: 12px;
  left: 6px;
  color: #fff;
}
.thumbOuter .video-title.hide {
  display: none;
}
@media (max-width: 660px) {
  .thumbOuter .video-title {
    line-height: 1rem;
    font-size: 14px;
    left: 4px;
  }
}
.thumbOuter img {
  border-radius: 15px;
  max-height: 340px;
}
.thumbOuter .playIcon {
  cursor: pointer;
}
.thumbOuter .playIcon img {
  border-radius: 15px;
  min-height: 0px;
}
@media (max-width: 660px) {
  .thumbOuter .playIcon img {
    max-width: 40px;
  }
}

.slick-prev {
  left: 0px !important;
  z-index: 9;
  transform: scaleX(-1) !important;
  background: rgb(56, 98, 36) !important;
  background: linear-gradient(to right, rgba(56, 98, 36, 0.9) 0%, rgba(99, 146, 80, 0.9) 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#386224", endColorstr="#639250",GradientType=1 ) !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% 0 0% 50%;
}
.slick-prev::before {
  display: none;
}

.slick-next {
  right: 0px !important;
  background: rgb(56, 98, 36) !important;
  background: linear-gradient(to right, rgba(56, 98, 36, 0.9) 0%, rgba(99, 146, 80, 0.9) 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#386224", endColorstr="#639250",GradientType=1 ) !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% 0% 0% 50%;
  z-index: 9;
}
.slick-next::before {
  display: none;
}

.gallerySeasoModal.mbp-0 .modal-body,
.sponsordModal.mbp-0 .modal-body {
  padding: 0;
}
.gallerySeasoModal.fixturesModal .modal-content,
.sponsordModal.fixturesModal .modal-content {
  background-image: url(../img/fixtures-bg.jpg);
}
.gallerySeasoModal.fixturesModal .modal-content::after,
.sponsordModal.fixturesModal .modal-content::after {
  background-color: rgba(0, 0, 0, 0.5);
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.gallerySeasoModal.fixturesModal .modal-body,
.gallerySeasoModal.fixturesModal .modal-header,
.sponsordModal.fixturesModal .modal-body,
.sponsordModal.fixturesModal .modal-header {
  z-index: 1;
}
.gallerySeasoModal.fixturesModal .modal-body h2,
.gallerySeasoModal.fixturesModal .modal-header h2,
.sponsordModal.fixturesModal .modal-body h2,
.sponsordModal.fixturesModal .modal-header h2 {
  color: #fff;
}
.gallerySeasoModal .season-list-item.digital h4,
.sponsordModal .season-list-item.digital h4 {
  align-items: baseline;
  flex-wrap: nowrap !important;
}
.gallerySeasoModal .season-list-item.digital h4 span,
.sponsordModal .season-list-item.digital h4 span {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-width: 90px;
}
.gallerySeasoModal .season-list-item.digital h4 span img,
.sponsordModal .season-list-item.digital h4 span img {
  margin-right: 5px;
}

.video-play-button {
  cursor: pointer;
}

.about-video {
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  position: fixed;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-video iframe {
  width: 90%;
  height: 90%;
}

#wheel {
  max-height: 50vh;
  max-width: 40vw;
  margin: 0 auto;
}

.teamStanding .slick-prev {
  margin-top: -15px !important;
}
.teamStanding .slick-next,
.teamStanding .slick-prev {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

@media (max-width: 767px) {
  .teamStanding .slick-next {
    right: -18px !important;
  }
  .teamStanding .slick-prev {
    left: -18px !important;
  }
}
.logout {
  position: fixed !important;
  left: 10px !important;
  bottom: 20px !important;
  top: auto !important;
  border-radius: 10px !important;
  min-width: 120px !important;
  padding: 0 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.shapeBox {
  position: relative;
}
.shapeBox .logout-btn {
  position: absolute;
  top: 20px;
  right: 30px;
  text-align: center;
  cursor: pointer;
}
@media (max-width: 767px) {
  .shapeBox .logout-btn {
    top: 20px;
    left: 10px;
    right: unset;
  }
}
.shapeBox .logout-btn img {
  width: 30px;
}
.shapeBox .logout-btn span {
  display: block;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.crop-container {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin: 8% auto 0 auto;
  min-height: 200px;
}

.disable-form {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: not-allowed;
  background-color: rgba(255, 255, 255, 0.5);
}

.user-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.user-icon span {
  color: #fff;
  font-weight: 600;
}

.sw-score {
  position: absolute;
  bottom: 12%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 99%;
}
.sw-score span {
  font-size: 1.5rem;
  font-weight: 600;
}

.sw-popup {
  position: absolute;
  width: 30%;
  height: 40%;
  top: 0px;
  left: 0px;
  background: #fff;
  right: 0;
  bottom: 0;
  margin: auto;
  box-shadow: 1px 1px 12px 0px rgba(0, 0, 0, 0.2);
  transition: all 1s;
}

.coming-soon .modal-content {
  background: url(../img/coming-soon.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media (max-width: 767px) {
  .coming-soon .modal-content {
    background-size: 100% auto;
  }
}

.coming-soon-img {
  max-width: 100%;
  max-height: 60vh;
  margin: 0 auto;
  width: auto;
}

.matchesTeams {
  cursor: pointer;
  opacity: 1;
}
.matchesTeams:hover {
  opacity: 0.8;
}

td {
  vertical-align: middle;
}

.ticket-icon {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4c7f44;
  border-radius: 50%;
  border: 2px solid #fdfefe;
}/*# sourceMappingURL=developer.css.map */