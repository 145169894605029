.createTeamModal {
  .viewTeam {
    width: auto;
    margin-left: 10px;
    border-radius: 4px;
    padding: 12px 20px;
  }

  h3 {
    font-size: 28px;
    color: #960396;
    margin-top: 15px;
  }

  .wintheMatchSec {
    margin-top: 40px;
    h4 {
      font-size: 24px;
      font-weight: 500;
    }
  }

  .selectTeam {
    margin-top: 30px;
    margin-bottom: 30px;
    @media (max-width: 767px) {
      display: flex;
    }
    img {
      margin-right: 10px;
      @media (max-width: 767px) {
        max-width: 30px;
      }
    }
    .form-check-inline {
      display: inline-flex;
      align-items: center;
    }
    label {
      font-size: 18px;
      font-weight: 600;
      color: #182115;
      @media (max-width: 767px) {
        font-size: 12px;
      }
    }

    .form-check-input {
      margin-right: 10px;
      margin-left: 0;
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 viewBox=%27-6 -6 12 12%27%3e%3ccircle r=%276%27 fill=%27%999%27/%3e%3c/svg%3e");
      &:checked {
        background-color: transparent;
        border-color: #3a6426;
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 viewBox=%27-6 -6 12 12%27%3e%3ccircle r=%276%27 fill=%27%233a6426%27/%3e%3c/svg%3e");
        background-size: 10px;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .form-check-input:checked ~ label {
      color: #3a6426;
    }
  }

  .playerCardTeam {
    border: 1px solid #c9d8c3;
    border-radius: 5px;
    display: flex;
    width: 100%;
    margin-bottom: 25px;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    .imageCol {
      width: 140px;
      border-right: 1px solid #c9d8c3;
      @media (max-width: 767px) {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #c9d8c3;
        text-align: center;
      }
      .form-check {
        margin: 10px 0 0 0px;
        .form-check-input {
          border-color: #386224;
          background: #63925019;
          margin-left: 0;
        }
        .form-check-input:checked[type="checkbox"] {
          background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e")
              no-repeat center,
            // SVG checkmark
            linear-gradient(
                180deg,
                #639250 0%,
                #386224 100%
              ); // Gradient background
          background-size: 12px, cover; // Sets SVG size and full cover for gradient
        }
      }
    }
    .detailsCol {
      width: calc(100% - 140px);

      @media (max-width: 767px) {
        width: 100%;
      }
      padding: 10px;
      .playerName {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        color: #386224;
      }

      .playerDetails {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        margin-top: 20px;
        p {
          font-size: 12px;
          color: #272727;
        }
        span {
          display: block;
          text-align: center;
          color: #272727;
          font-size: 16px;
          font-weight: bold;
        }

        .totalRaids {
          border-right: 1px solid rgba(126, 126, 126, 0.5);
          width: 26%;
          padding: 5px;
        }
        .successfulRaids {
          border-right: 1px solid rgba(126, 126, 126, 0.5);
          width: 41%;
        }
        .superRaids {
          width: 33%;
        }
      }

      .playerRole {
        padding-top: 20px;
        .role {
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          border-radius: 50%;
          background: transparent
            linear-gradient(180deg, #639250 0%, #386224 100%) 0% 0% no-repeat
            padding-box;
          font-size: 20px;
          font-weight: bold;
          color: #fff;
          &.selected {
            background: #aa3e97;
          }
        }
      }
    }

    &.playerPoints {
      .detailsCol {
        padding: 20px;
        .playerName {
          text-align: left;
        }
        .playerDetails {
          display: flex;
          width: 100%;
          text-align: left;
          justify-content: space-between;
          align-items: center;
          .playerRole {
            padding-top: 0;
          }
          .superRaids {
            width: auto;
          }
        }
      }
    }
  }
}

.createteamWraper {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid #bedeaf;
  border-radius: 20px;
  padding: 50px 30px;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    padding: 30px 15px;
  }
  .filterSec {
    position: inherit;
    @media (max-width: 767px) {
      flex-wrap: wrap;
      margin-top: 10px;
      justify-content: center;
      .viewTeam {
        margin-top: 10px;
      }
    }
  }
}
