@import "variables";
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  box-sizing: border-box;
}
body {
  padding: 0px;

  font-family: "Poppins", sans-serif;
  // font-size: clamp(16px, calc(20px + (36 - 20) * (100vw - 768px)/(1920 - 768)), 48px);

  margin: 0px;
  overflow-x: hidden;
  min-height: 100%;
  overflow-y: auto;

  &.hiddenscroll {
    overflow: hidden;
  }
}

@media (min-width: 1536px) {
  .modal-xl {
    --bs-modal-width: 1320px;
  }
}

@mixin has-nth($expression, $element: "*") {
  &:nth-child(#{$expression}) {
    @content;
  }
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  max-width: 100%;
}

body,
html {
  padding: 0px;
  width: 100%;
  margin: 0px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  @extend.oswald;
}

ul,
ol,
li,
a,
span,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0px;
  margin: 0px;
  outline: none;
  text-decoration: none;
}

.oswald {
  font-family: "Oswald", sans-serif;
}

.borderR5 {
  border-radius: 5px;
}

.borderR10 {
  border-radius: 10px;
}

%dynamicBg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 0;
}

%light {
  font-weight: 300;
}

%regular {
  font-weight: 400;
}

%semiBold {
  font-weight: 600;
}

%medium {
  font-weight: 500;
}

%bold {
  font-weight: bold;
}

%extraBold {
  font-weight: 800;
}

%transition {
  transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
}

a {
  &:hover {
    text-decoration: none;
  }
}

p {
  line-height: normal;
  font-weight: normal;
}

$units: (
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  42,
  45,
  50,
  55,
  60,
  65,
  70,
  75,
  80,
  85,
  90,
  99
);
@each $unit in $units {
  .mT#{$unit} {
    margin-top: #{$unit}px;
  }
  .mB#{$unit} {
    margin-bottom: #{$unit}px;
  }
  .mR#{$unit} {
    margin-right: #{$unit}px;
  }
  .mL#{$unit} {
    margin-left: #{$unit}px;
  }
}

$units: (
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  42,
  45,
  50,
  55,
  60,
  65,
  70,
  75,
  80,
  85,
  90,
  99
);
@each $unit in $units {
  .pT#{$unit} {
    padding-top: #{$unit}px;
  }
  .pB#{$unit} {
    padding-bottom: #{$unit}px;
  }
  .pR#{$unit} {
    padding-right: #{$unit}px;
  }
  .pL#{$unit} {
    padding-left: #{$unit}px;
  }
}

.wrapper {
  position: relative;
  width: 100%;
  margin: 0 auto;

  .videoOuter {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 100%;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.btn {
  display: inline-flex;
  width: 100px;
  border-radius: 10px;
  text-align: center;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  @extend.gradientgreen;
  color: $white;
  border: none;

  z-index: 11;
  bottom: 10%;
  left: 0;
  right: 0;
  margin: 0 auto;
  @extend.oswald;
  cursor: pointer;
  &.skip {
    position: fixed;
  }
  &.send {
    font-size: 18px;
    font-weight: 600;
    min-width: 155px;

    @media (max-width: 767px) {
      min-width: 120px;
      margin: 0 10px;
    }
  }
  &:hover {
    background: -moz-linear-gradient(
      left,
      rgba(56, 98, 36, 1) 0%,
      rgba(230, 204, 103, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      left,
      rgba(56, 98, 36, 1) 0%,
      rgba(230, 204, 103, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to right,
      rgba(56, 98, 36, 1) 0%,
      rgba(230, 204, 103, 1) 100%
    );
    color: $white;
  }
}

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 111;
  .notifaction {
    margin-right: 10px;
  }
}

.imageOuter {
  height: 100vh;

  img {
    width: 100%;
    height: 100%;
  }
}

.hotspots {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  z-index: 100;
}
.hotspots .hotspot-item {
  position: absolute;

  font-size: 14px;
  font-weight: 500;
  // -webkit-animation: action 1s infinite alternate;
  // animation: action 1s infinite alternate;
  @media (max-width: 1366px) {
    font-size: 12px;
  }

  a {
    @extend.gradientgreen;
    // min-width: 72px;
    min-height: 30px;
    border-radius: 6px;
    // border: 1px solid $white;
    padding-right: 10px;
    padding-left: 20px;
    color: $white;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.hotspot-item-1 {
    left: 11%;
    top: 26.5%;
  }
  &.hotspot-item-2 {
    left: 31%;
    top: 30%;
  }
  &.hotspot-item-3 {
    left: 48%;
    top: 30%;
  }
  &.hotspot-item-4 {
    left: 66%;
    top: 30%;
  }
  &.hotspot-item-5 {
    left: 83%;
    top: 24%;
  }
  &.hotspot-item-6 {
    left: 39.5%;
    top: 48.5%;
  }
  &.hotspot-item-7 {
    left: 55%;
    top: 48.5%;
  }

  &.hotspot-item-8 {
    left: 31.2%;
    top: 54.8%;
  }
  &.create-team {
    right: 15%;
    top: 30%;
    left: auto;
  }
  &.hotspot-item-9 {
    left: 48%;
    top: 58.8%;
  }
  &.hotspot-item-10 {
    left: 62.2%;
    top: 63%;
  }

  &.hotspot-item-11 {
    left: 63.6%;
    top: 69.5%;
  }

  .arrow_box {
    border: none;
    position: absolute;
    bottom: 1px;
  }
  .arrow_box:after,
  .arrow_box:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .arrow_box:after {
    border-color: rgba(88, 120, 65, 0);
    border-top-color: #587841;
    border-width: 9px;
    margin-left: -15px;
  }
  .arrow_box:before {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #fff;
    border-width: 10px;
    margin-left: -16px;
  }
}
@-webkit-keyframes action {
  0% {
    // transform: translateY(0);
    transform: scale(1);
  }
  100% {
    // transform: translateY(-10px);
    transform: scale(0.9);
  }
}

@keyframes action {
  0% {
    // transform: translateY(0);
    transform: scale(1);
  }
  100% {
    // transform: translateY(-10px);
    transform: scale(0.9);
  }
}
.pulsating-circle {
  position: absolute;
  left: 10px;
  top: 52.6%;
  transform: translateX(-50%) translateY(-50%);
  width: 8px;
  height: 8px;

  &:before {
    content: "";
    position: relative;
    display: block;
    width: 200%;
    height: 200%;
    box-sizing: border-box;
    margin-left: -50%;
    margin-top: -50%;
    border-radius: 45px;
    background-color: #fff;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

.footer {
  @extend.gradientgreen;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 15px 0;
  z-index: 101;

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#386224+0,639250+100 */
  background: rgb(56, 98, 36); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgba(56, 98, 36, 0.9) 0%,
    rgba(99, 146, 80, 0.9) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(56, 98, 36, 0.9) 0%,
    rgba(99, 146, 80, 0.9) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(56, 98, 36, 0.9) 0%,
    rgba(99, 146, 80, 0.9) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#386224', endColorstr='#639250',GradientType=1 ); /* IE6-9 */

  ul {
    display: flex;
    justify-content: space-around;
    align-content: center;

    margin-bottom: 0;
    padding: 0 3rem;
    padding-bottom: 0;
    @media (max-width: 1535px) {
      padding: 0 2rem;
      padding-bottom: 0px;
    }
    @media (max-width: 990px) {
      padding: 0;
    }
    li {
      display: inline-flex;
      padding: 0 1rem;
      flex-direction: column;
      justify-content: flex-end;
      color: $white;
      cursor: pointer;
      font-size: 14px;
      position: relative;
      @extend%transition;
      @media (max-width: 1535px) {
        font-size: 14px;
        padding: 0 0.5rem;
      }
      @media (max-width: 1366px) {
        font-size: 13px;
        white-space: nowrap;
        img {
          max-height: 24px;
        }
      }

      span {
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @extend%transition;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
.footerLinks {
  display: flex;
  justify-content: flex-end;

  span {
    color: $white;
    font-size: 14px;
    padding: 0 20px;
    position: relative;
    &::after {
      content: "|";
      position: absolute;
      right: 0;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}

.modalPages {
  .modal-header {
    border-bottom: none;
    padding-left: 3rem;
    justify-content: center;
    @media (max-width: 767px) {
      padding-left: 2.3rem;
    }
  }

  .btn-close {
    background: #578544;
    border-radius: 50%;
    color: $white;
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
    opacity: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    right: 20px;
    top: 20px;
    width: 40px;
    height: 40px;
    z-index: 11;
    @media (max-width: 767px) {
      // top: -10px;
      right: 20px;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  h1,
  .headingMain {
    color: $white;
    font-size: 20px;
    @extend%bold;
    padding: 13px 20px;
    min-height: 54px;
    font-weight: bold;
    @extend.gradientgreen;
    transform: skew(345deg);
    position: relative;
    @media (max-width: 1536px) {
      padding: 13px 20px;
    }
    &::after {
      content: "";
      display: block;
      background: url(../img/line-shape.svg) no-repeat 0 0;
      width: 33px;
      height: 56px;
      position: absolute;
      left: -36px;
      top: 0px;
      transform: skew(15deg);
    }
    &::before {
      content: "";
      display: block;
      background: url(../img/line-shape.svg) no-repeat 0 0;
      width: 33px;
      height: 56px;
      position: absolute;
      right: -36px;
      top: 0px;
      transform: skew(15deg);
    }
    span {
      display: block;

      transform: skew(15deg);
      @media (min-width: 1160px) {
        min-width: 100px;
        text-align: center;
      }
    }
    // span {
    //   width: 150px;
    //   height: 5px;
    //   background: $green;
    //   display: inline-flex;
    //   margin-left: 10px;
    // }
  }
  .modal-content {
    border-radius: 0;
    background: url(../img/sponsord-background.png) no-repeat;
    background-size: cover;
  }
}

.sponsorLogos {
  background: $white;
  box-shadow: 0px 0px 30px #0000000d;
  border-radius: 10px;
  min-height: 250px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 990px) {
    max-width: 140px;
    margin: 0 auto;
    margin-bottom: 30px;
    min-height: inherit;
    padding: 10px;
  }

  h4 {
    font-size: 19px;
    color: #4a5745;
    margin-top: 15px;
    @extend.oswald;
    font-weight: normal;
  }
}
.contactOuter {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  height: 100%;
}
.conatctModal {
  h1 {
    background: $white;
    color: $green;
  }
  h1::before,
  .headingMain::before,
  h1::after,
  .headingMain::after {
    background: url(../img/line-shape-white.svg) no-repeat 0 0;
  }
  .modal-content {
    padding: 0;
  }
  .getintouch {
    width: 100%;
    @extend.gradientgreen;
    min-height: 100%;
    padding: 2rem 1.5rem;
    // border-radius: 18px 0 0 18px;
    color: $white;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    position: relative;
    div {
      position: relative;
      z-index: 1;
      text-align: center;
      max-width: 480px;
    }
    &:after {
      content: "";
      display: block;
      width: 98%;
      height: 100%;
      position: absolute;
      bottom: 0;
      background-image: url(../img/p2-left.png);
      background-repeat: no-repeat;
      background-position: 100% top;
      @media (max-width: 1536px) {
        background-size: auto 100%;
      }
      @media (max-width: 767px) {
        background-size: 35% auto;
        background-position: bottom right;
      }
    }
    &:before {
      content: "";
      display: block;
      width: 98%;
      height: 100%;
      position: absolute;
      bottom: 0;
      background-image: url(../img/p2.png);
      background-repeat: no-repeat;
      background-position: 0% top;
      @media (max-width: 1536px) {
        background-size: auto 100%;
      }
      @media (max-width: 767px) {
        background-size: 35% auto;
        background-position: bottom left;
      }
    }
    @media (max-width: 767px) {
      width: 100%;
      flex-wrap: nowrap;
    }
    .socailIcons {
      margin-top: 30px;
      text-align: center;
      a {
        padding: 0 0.5rem;
        &:last-child {
          padding: 0;
        }
      }
    }
    address {
      margin-top: 40px;
      font-size: 18px;
      @media (max-height: 900px) {
        margin-top: 20px;
      }
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }

    h2 {
      font-size: 38px;
      @extend%medium;
      margin-bottom: 10px;
      align-items: center;
      @media (max-width: 1536px) {
        font-size: 20px;
      }
      img {
        margin-right: 5px;
      }
    }
    h3 {
      font-size: 28px;
      @extend%medium;
      justify-content: center;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      @media (max-width: 1536px) {
        font-size: 18px;
      }

      img {
        margin-right: 5px;
      }
    }
    p {
      font-size: 20px;
      @media (max-width: 1536px) {
        font-size: 18px;
      }
      @media (max-width: 767px) {
        font-size: 16px;
      }
      span {
        font-size: 15px;
        @extend%medium;
        @media (max-width: 1536px) {
          font-size: 14px;
        }
      }
    }
  }
  .modal-body {
    padding: 0;
  }
}
.customScroll {
  @media (max-height: 880px) {
    .view {
      max-height: 410px !important;
      &.small {
        max-height: 280px !important;
      }
    }
  }
}

.formSec {
  flex-basis: 20%;
  padding: 1rem 2rem 1rem 3rem;
  @media (max-width: 767px) {
    flex-basis: 10%;
    padding: 1.5rem;
  }
  .customScroll {
    // min-height: 50vh;
    // padding-right: 1rem;
    overflow-x: hidden;
    @media (max-height: 900px) {
      max-height: 380px !important;
    }

    form {
      margin-right: 1rem;
      padding-top: 10px;
    }
    .mapSec {
      margin-right: 1rem;
    }
  }
  .modal-header {
    margin-bottom: 20px;
  }

  .form-floating > .form-control-plaintext:focus,
  .form-floating > .form-control-plaintext:not(:placeholder-shown),
  .form-floating > .form-control:focus,
  .form-floating > .form-control:not(:placeholder-shown) {
    top: -30px;
  }

  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-select ~ label {
    transform: scale(0.85) translateY(-1.8rem) translateX(0.15rem);
  }

  .form-floating > .form-control-plaintext:focus,
  .form-floating > .form-control-plaintext:not(:placeholder-shown),
  .form-floating > .form-control:focus,
  .form-floating > .form-control:not(:placeholder-shown) {
    padding-top: 0.75rem;
  }
  .form-floating {
    margin-bottom: 30px;
  }
  .form-control {
    border-color: #dce2e6;
    border-radius: 10px;
  }
  .form-control:focus {
    box-shadow: none;
    outline: none;
    border-color: #333333;
  }
  textarea {
    height: 160px !important;
    resize: none;
    @media (max-height: 900px) {
      height: 100px !important;
    }
  }
  label {
    font-size: 14px;
    color: #6f6f6f;
  }
}

.thumbOuter {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  img {
    width: 100%;
    @media (max-width: 767px) {
      width: 100%;
      max-width: 300px;
      min-height: inherit !important;
    }
  }
  .playIcon {
    position: absolute;
  }
}
.modal-content {
  padding: 0 2rem 2rem;
  @media (max-width: 1536px) {
    padding: 0 1.8rem 1.8rem;
  }
  @media (max-width: 767px) {
    padding: 0;
  }
  .modal-header {
    padding-top: 2rem;
  }
}
.faqPage {
  .modal-content {
    background: url(../img/sponsord-background.png) no-repeat;
    background-size: cover;
  }
  .modal-content {
    padding: 0 2.8rem 2.8rem;
    @media (max-width: 1536px) {
      padding: 0 2.4rem 2.4rem;
    }
    @media (max-width: 767px) {
      padding: 0 0.4rem 0.4rem;
    }
    .modal-header {
      padding-top: 2rem;
    }
  }
  .accordion {
    margin-top: 30px;
    border: none;
    background-color: transparent;
    .accordion-item {
      border: none;
      border-top: 1px solid rgba(112, 112, 112, 0.8);
      border-radius: 0;
      background-color: transparent;
      .accordion-button {
        padding-left: 0;
        padding-right: 0;
        box-shadow: none;
        background-color: transparent;
      }
      .accordion-header {
        font-size: 16px;
        font-family: "Poppins", sans-serif;
      }
    }
    .accordion-body {
      color: #4a5745;
      font-size: 14px;
      padding-left: 0;
      padding-right: 0;
    }
    .accordion-button:focus {
      box-shadow: none;
      border-color: rgba(112, 112, 112, 0.8);
    }
    .accordion-button:not(.collapsed) {
      background-color: transparent;
      color: #182115;
    }
  }
}

.sponsordModal {
  .modal-content {
    background: url(../img/sponsord-background.png) no-repeat;
    background-size: cover;
  }
  h2 {
    font-size: 24px;
    letter-spacing: 0.7px;
    margin-bottom: 30px;
  }
}

.loginPage {
  h2 {
    font-size: 24px;
    color: $green;
    margin-top: 20px;
  }
  .formSec {
    padding: 1rem 0;
  }
  .modal-header,
  .modal-body {
    padding: 0;
  }
  .modal-content {
    padding: 1.5rem;
  }
  .nav-tabs {
    border-bottom: 6px solid rgba(232, 244, 226, 0.4);
    justify-content: space-around;
    @extend.oswald;

    .nav-item {
      width: 50%;
      text-align: center;
    }
    .nav-link {
      border: none;
      color: #797979;
      font-size: 20px;
      width: 100%;
      position: relative;
      &::after {
        width: 0;
        height: 6px;
        margin: 0 auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -6px;
        content: "";
        display: block;
        @extend%transition;
      }
      &.active {
        color: #386224;

        &::after {
          @extend.gradientgreen;
          width: 100%;
        }
      }
    }
  }
  .forgotPassword {
    display: flex;
    justify-content: flex-end;
    a {
      color: $green;
      font-size: 14px;
      text-decoration: underline;
    }
  }
  .send {
    width: 100%;
    margin-top: 20px;
  }
  &.modal {
    --bs-modal-width: 440px;
  }
}

.aboutModal {
  .btn-close {
    top: 10px;
    z-index: 11;
    right: 13px;
  }
  .aboutBanner {
    margin-bottom: 30px;
    @media (max-width: 767px) {
      margin: 0 auto;
      margin-bottom: 40px;
      margin-top: 0px;
    }
  }
  .modal-content {
    padding: 0 1.1rem 2.1rem;
    background: url(../img/sponsord-background.png) no-repeat;
    background-size: cover;
    @media (max-width: 767px) {
      padding: 0;
    }
  }
  p {
    margin-bottom: 20px;
    color: #4a5745;
    font-size: 16px;
    @media (max-width: 1536px) {
      font-size: 13px;
      margin-bottom: 10px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  .about-text {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border: 1px solid #bedeaf;
    border-radius: 20px;
    padding: 50px;
    margin-bottom: 30px;

    @media (max-width: 767px) {
      padding: 10px;
    }
  }

  .customScroll > div {
    overflow-x: hidden !important;
  }

  .seasonWinners {
    background: url(../img/seasion-banner-bg.jpg) no-repeat;
    background-size: cover;
    padding: 5rem 0 10rem;
    border-radius: 20px;

    h2 {
      color: $white;
      text-align: center;
      font-weight: bold;
      text-transform: capitalize;
      margin-bottom: 30px;
      @media (max-width: 767px) {
        font-size: 20px;
      }
    }
  }

  .playIcon {
    position: relative;
    margin: 50px 0;
  }

  .video-play-button {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-sizing: content-box;
    display: block;
    width: 22px;
    height: 34px;
    /* background: #fa183d; */
    border-radius: 50%;
    padding: 18px 20px 18px 28px;
  }

  .video-play-button:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 60px;
    height: 60px;
    border: 2px solid #459345;
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
  }

  .video-play-button:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 60px;
    height: 60px;
    background: #459345;
    border: 1px solid #459345;
    border-radius: 50%;
    transition: all 200ms;
  }

  .video-play-button:hover:after {
    background-color: darken(#459345, 10%);
  }

  .video-play-button img {
    position: relative;
    z-index: 3;
    max-width: 100%;
    width: auto;
    height: auto;
  }

  .video-play-button span {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    border-left: 24px solid #fff;
    border-top: 17px solid transparent;
    border-bottom: 17px solid transparent;
  }

  @keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
    }
  }

  .followUsOn {
    @extend.gradientgreen;

    padding: 20px;
    color: $white;
    border-radius: 10px;
    margin-top: 30px;
    display: flex;
    width: 100%;
    @media (max-width: 1536px) {
      align-items: center;
    }
    position: relative;
    &::after {
      content: "";
      display: block;
      background: url(../img/dots-social.svg) no-repeat;
      background-position: right 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }
    div {
      position: relative;
      z-index: 1;
    }
    .socailIcons {
      background: #fffaff;
      border-radius: 10px;
      width: 5rem;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 1;
      margin-right: 30px;
      @media (max-width: 1536px) {
        width: 3rem;
        height: 3rem;
        margin-right: 10px;
        img {
          max-width: 80%;
          max-height: 90%;
        }
      }
    }
    .count {
      font-size: 42px;
      font-weight: 600;
      @extend.oswald;
    }
    p {
      color: $white;
      margin-bottom: 0;

      @media (max-width: 990px) {
        font-size: 12px;
      }
    }
  }
  @media (max-width: 990px) {
    .p-3 {
      padding: 0 !important;
    }
  }
}

.teamStanding {
  background: url(../img/team-standing-bg.jpg) no-repeat 0 0;
  background-size: cover;
  padding: 30px;
  border-radius: 20px;
  margin-top: 30px;
  .title {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
    margin-bottom: 30px;
  }

  .seasonPostion {
    border: 2px solid #ffffff31;
    border-radius: 10px 60px 10px 50px;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2.4rem;
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
    h3 {
      font-size: 30px;
      font-weight: 600;
      letter-spacing: 0.75px;
      opacity: 0.9;
      margin-top: 20px;
      position: relative;
      margin-bottom: 30px;
      &::after {
        content: "";
        display: block;
        width: 40px;
        height: 5px;
        margin: 0 auto;
        position: absolute;
        background: $white;
        bottom: -12px;
        left: 0;
        right: 0;
        border-radius: 30px;
        opacity: 0.9;
      }
    }
    p {
      font-size: 19px;
      @extend.oswald;
      letter-spacing: 0.47px;
      color: #ffffff;
      opacity: 0.9;
    }
  }
}

.gallerySeasoModal {
  .nav-tabs {
    border: none;
    justify-content: center;
    flex-wrap: nowrap;
    overflow-x: auto;
    max-width: 100%;
    overflow-y: hidden;
    min-height: 60px;

    @media (max-width: 767px) {
      overflow: auto hidden;
    }
    .nav-item {
      padding: 0 12px;
      @media (max-width: 767px) {
        padding: 0 9px;
      }
      &:first-child {
        padding-left: 0;
      }
    }
    .nav-link {
      border: none;
      border: 1px solid #386224;
      border-radius: 4px;
      color: $green;
      position: relative;
      font-size: 1.25rem;
      font-weight: 500;
      white-space: nowrap;
      @media (max-width: 767px) {
        font-size: 1rem;
      }

      &.active {
        @extend.gradientgreen;
        color: $white;
        border-color: transparent;
        &:after,
        &:before {
          top: 100%;
          left: 50%;
          border: solid transparent;
          content: "";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &:after {
          border-color: rgba(88, 120, 65, 0);
          border-top-color: #587841;
          border-width: 9px;
          margin-left: -15px;
        }
      }
    }
  }

  .season-list-item {
    position: relative;
    margin-bottom: 30px;
    @extend%transition;
    .matchimage {
      width: 100%;
      height: 250px;
      object-fit: cover;
      object-position: top;
    }
    .overlayList {
      @extend%transition;
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $white;
      padding: 1rem;
      z-index: 1;
      top: 0;
      background-color: rgba(0, 0, 0, 0.7);
      opacity: 0;
      h3 {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.57px;
        text-align: center;
      }
      .view {
        border: 1px solid #ffffff2f;
        border-radius: 55px;
        color: $white;
        @extend.gradientgreen;
        min-width: 110px;
        text-align: center;
        padding: 3px 10px;
        margin-top: 20px;
        &.icon {
          background: none;
          padding: 0;
          border: none;
          min-width: inherit;
        }
      }
    }

    &:hover {
      .overlayList {
        opacity: 1;
      }
    }
    .matchNumb {
      position: absolute;
      border: 1px solid #ffffff;
      border-radius: 0px 4px 4px 0px;
      @extend.gradientgreen;
      top: 30px;
      color: $white;
      z-index: 2;
      padding: 3px 10px;
      left: 0;
      font-size: 14px;
      @extend.oswald;
      letter-spacing: 0.35px;
    }

    &.media-listing {
      box-shadow: 0px 0px 10px #0000001a;
      border: 10px solid #ffffff;
      border-radius: 4px;
      background-color: #fff;
      opacity: 1;
      .overlayList {
        @extend.gradientgreen;
      }
    }

    &.digital {
      border-radius: 20px;
      padding-bottom: 10px;
      .matchimage {
        min-height: inherit;
        border-radius: 15px;
      }
      h4 {
        font-size: 19px;
        display: flex;
        justify-content: space-between;
        color: #182115;
        padding: 15px 0;
        min-height: 78px;
        flex-wrap: wrap;

        span {
          color: #4a5746;
          font-size: 14px;
          font-weight: normal;
          @media (max-width: 767px) {
            display: block;
            padding-top: 10px;
          }
        }
      }
      .readMore {
        color: #3a6426;
        font-size: 16px;
        font-weight: 600;
        text-decoration: underline;
        margin-bottom: 20px;
      }
      p {
        color: #4a5745;
        font-size: 16px;
        margin-bottom: 10px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        min-height: 72px;
        @media (max-width: 767px) {
          -webkit-line-clamp: inherit;
        }
      }
    }
  }
}
.errorText {
  color: red;
  font-size: 12px;
}
.form-control {
  &.is-valid {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
    background-position: right calc(0.375em + 0.1875rem) center;
    background-repeat: no-repeat;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    border-color: var(--bs-form-valid-border-color);
    padding-right: calc(1.5em + 0.75rem);
  }
  &.is-invalid {
    @extend.is-valid;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
  }
}
.galleryPageOuter {
  .hotspots {
    .hotspot-item {
      min-width: 5%;
      min-height: 16%;
      cursor: pointer;
      .pulsating-circle {
        right: 0;
        margin: 0 auto;
        left: 0;
        width: 14px;
        height: 14px;
      }
      a {
        background: none;
        border: none;
        .arrow_box {
          display: none;
        }
        @media (max-width: 1800px) {
          min-width: 56px;
        }
        @media (max-width: 1536px) {
          min-width: 50px;
        }
      }
      &.hotspot-item-1 {
        left: 18.8%;
        top: 45.5%;
      }
      &.hotspot-item-2 {
        left: 24.8%;
        top: 45.5%;
      }
      &.hotspot-item-3 {
        left: 30.8%;
        top: 45.5%;
      }
      &.hotspot-item-4 {
        left: 36.8%;
        top: 45.5%;
      }
      &.hotspot-item-5 {
        left: 42.8%;
        top: 45.5%;
      }
      &.hotspot-item-6 {
        left: 48.5%;
        top: 45.5%;
      }
      &.hotspot-item-7 {
        left: 54.3%;
        top: 45.5%;
      }
      &.hotspot-item-8 {
        left: 60.3%;
        top: 45.5%;
      }
      &.hotspot-item-9 {
        left: 65.8%;
        top: 45.5%;
      }
      &.hotspot-item-10 {
        left: 72%;
        top: 45.5%;
      }
      &.hotspot-item-11 {
        left: 77.8%;
        top: 45.5%;
      }
      &.hotspot-item-12 {
        left: 14.8%;
        top: 71.9%;
        min-width: 12.2%;
        min-height: 9.8%;
        .pulsating-circle {
          top: 13%;
          left: -72%;
        }
      }
      &.hotspot-item-13 {
        left: 33.4%;
        top: 74.2%;
        min-width: 10.1%;
        min-height: 8%;

        .pulsating-circle {
          left: -53.8%;
          top: -10%;
        }
      }
      &.hotspot-item-14 {
        left: 45.2%;
        top: 64.9%;
        min-width: 10%;
        min-height: 4%;
        .pulsating-circle {
          left: -65%;
          top: 49%;
        }
      }

      &.hotspot-item-15 {
        left: 57%;
        top: 69.2%;
        min-width: 9.2%;
        min-height: 12.6%;
        .pulsating-circle {
          left: -47%;
          top: 35%;
        }
      }

      &.hotspot-item-16 {
        left: 73.8%;
        top: 70.2%;
        min-width: 12.2%;
        min-height: 11.6%;
        .pulsating-circle {
          left: 80%;
          top: 65%;
        }
      }
    }
  }
}

.newsEventPageOuter {
  .hotspot-item {
    width: 16%;
    height: 16%;
    cursor: pointer;
    a {
      background: no-repeat;
      border: none;
      height: 100%;
      .pulsating-circle {
        width: 18px;
        height: 18px;
        left: 52.1%;
        @media (max-width: 1536px) {
          width: 12px;
          height: 12px;
        }
      }

      @media (max-width: 1800px) {
        min-width: 56px;
      }
      @media (max-width: 1536px) {
        min-width: 50px;
      }
    }
    &.hotspot-item-1 {
      left: 33.5%;
      top: 38.7%;
      @media (max-width: 1660px) {
        top: 39%;
      }
    }
    &.hotspot-item-2 {
      left: 50.8%;
      top: 38.7%;
      @media (max-width: 1660px) {
        top: 39%;
      }
    }
  }
}

.membersLoungePage {
  .hotspots {
    .hotspot-item {
      a {
        background: no-repeat;
        border: none;
        height: 100%;
        .pulsating-circle {
          width: 18px;
          height: 18px;
          left: 50%;
          @media (max-width: 1536px) {
            width: 12px;
            height: 12px;
          }
        }

        @media (max-width: 1800px) {
          min-width: 56px;
        }
        @media (max-width: 1536px) {
          min-width: 50px;
        }
      }
      &.hotspot-item-1 {
        top: 58.5%;
        left: 5%;
        @media (max-width: 1660px) {
          top: 58.5%;
          left: 4%;
        }
        @media (max-width: 1440px) {
          top: 61.6%;
          left: 4%;
        }
        @media (max-width: 990px) {
          top: 62%;
          left: 3%;
        }
      }
      &.hotspot-item-2 {
        top: 57%;
        left: 13%;
        @media (max-width: 1660px) {
          // top: 58%;
          // left: 16%;
        }
        @media (max-width: 1440px) {
          top: 59%;
          left: 13%;
        }
        @media (max-width: 990px) {
          top: 60%;
          left: 12%;
        }
      }
      &.hotspot-item-3 {
        top: 56%;
        left: 20.5%;
        @media (max-width: 1660px) {
          top: 55.5%;
          left: 19.5%;
        }
        @media (max-width: 1440px) {
          top: 57.5%;
          left: 19.5%;
        }
        @media (max-width: 990px) {
          top: 57.5%;
          left: 18.5%;
        }
      }

      &.hotspot-item-4 {
        top: 55.3%;
        left: 25.5%;
        @media (max-width: 1660px) {
          top: 54.5%;
          left: 24.5%;
        }
        @media (max-width: 1440px) {
          top: 56.2%;
          left: 24.5%;
        }
        @media (max-width: 990px) {
          top: 56.2%;
          left: 23.5%;
        }
      }

      &.hotspot-item-5 {
        top: 54.7%;
        left: 29.7%;
        @media (max-width: 1660px) {
          top: 53.3%;
          left: 28.7%;
        }
        @media (max-width: 1440px) {
          top: 55%;
          left: 28.7%;
        }
        @media (max-width: 990px) {
          top: 55%;
          left: 27.7%;
        }
      }

      &.hotspot-item-6 {
        top: 57.5%;
        left: 60.3%;
        width: 7.4%;
        height: 9%;
        @media (max-width: 1660px) {
          top: 58%;
          left: 60.3%;
        }
      }
      &.hotspot-item-7 {
        top: 62%;
        left: 68.3%;
        width: 7.4%;
        height: 11%;
        @media (max-width: 1660px) {
          top: 62%;
          left: 68.3%;
        }
      }
    }
  }
}

.mediaOuter {
  padding: 10px;
  overflow-x: hidden;
}

.gallerySeasoModal {
  .modal-content {
    background: url(../img/sponsord-background.png) no-repeat;
    background-size: cover;
  }
  .nav-tabs.statsTabs {
    justify-content: flex-start;
  }
  .filterSec {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 1rem;
    @media (max-width: 767px) {
      position: relative;
      margin-bottom: 20px;
    }
    .form-group {
      margin-left: 15px;
      select {
        border-color: #386224;
        height: 50px;
        border-radius: 4px;
      }
    }
  }
  .nav-tabs .nav-link {
    background: $white;
  }
  td {
    font-size: 20px;
    font-weight: 600;
    padding: 10px 20px;
    @media (max-width: 767px) {
      font-size: 14px;
      padding: 5px 10px;
    }
  }
  thead {
    border-radius: 4px;
    th {
      background: #960396;
      padding: 15px;
      color: $white;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }

    .table > :not(caption) > * > * {
      background: #960396;
    }
  }
}

.loungeModal {
  @media (max-height: 880px) {
    .customScroll .view {
      max-height: 300px !important;
    }
  }
}

.resultMatchs {
  .resultTitle {
    @extend.gradientgreen;
    color: $white;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    .matchNo {
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 110px;
      font-size: 14px;
      text-transform: uppercase;
      color: $white;
      font-weight: 500;
      padding: 7px 10px;
      @media (max-width: 575px) {
        height: 30px;
        font-size: 11px;
      }
    }
    .dayOuter {
      padding-left: 50px;
      position: relative;
      @media (max-width: 575px) {
        padding-left: 35px;
      }
      .dateTitme {
        font-size: 12px;
        span {
          display: block;
        }
      }
    }
    .date {
      position: absolute;
      left: 0;
      font-weight: 600;
      font-size: 34px;
      top: -6px;
      @media (max-width: 575px) {
        font-size: 24px;
      }
    }
  }
}

.tab-select {
  background-color: transparent;
  color: #fff;
  border: none;
}
.resultMatchs {
  margin-bottom: 30px;
}
.matchesTeams {
  background: url(../img/fixture-card-bg.jpg) no-repeat;
  background-size: cover;
  padding-bottom: 15px;
  background-position: 0 30%;
  .matchBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    color: $white;
    font-size: 26px;
    font-weight: 800;
    color: $white;
    img {
      max-height: 80px;
      @media (max-width: 575px) {
        max-height: 55px;
      }
    }
  }
  .teamsName {
    font-size: 18px;
    font-weight: 600;
    color: $white;
    text-align: center;
    @media (max-width: 575px) {
      font-size: 16px;
    }
    span {
      font-weight: 800;
      color: #1e864e;
    }
  }
  p {
    font-size: 14px;
    text-align: center;
    color: $white;
    margin-bottom: 20px;
  }
}

.topPerformers {
  h2 {
    font-size: 36px;
    @extend%bold;
    margin-bottom: 4rem;
    text-align: center;
    color: $green;
    @media (max-width: 1536px) {
      margin-bottom: 1rem;
      font-size: 30px;
    }

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }

  .pointsTable {
    @extend.gradientgreen;
    color: $white;
    border-radius: 10px;
    margin: 0 40px;
    @media (max-width: 767px) {
      margin: 0;
    }
    ul {
      list-style: none;
      li {
        display: flex;
        justify-content: space-between;
        padding: 15px 20px 10px;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 600;
        border-bottom: 1px solid rgba(255, 255, 255, 0.6);
        &:last-child {
          border-bottom: none;
        }
        @media (max-width: 1536px) {
          font-size: 1rem;
        }
        span {
          font-size: 1.8rem;
          @media (max-width: 1536px) {
            font-size: 1.4rem;
          }
          @media (max-width: 767px) {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .performerPlayer {
    position: relative;
    text-align: center;
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
    .plyerName {
      font-family: "Poppins", sans-serif;
      @extend%bold;
      color: $white;
      @extend.gradientgreen;
      text-align: center;
      font-size: 18px;
      padding: 1rem;
      @media (max-width: 1536px) {
        font-size: 15px;
      }
    }
  }
}
.did-floating-label-content {
  position: relative;
  margin-bottom: 20px;
}
.did-floating-label {
  color: #1e4c82;
  font-size: 13px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 11px;
  padding: 0 5px;
  background: #fff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.did-floating-input,
.did-floating-select {
  font-size: 12px;
  display: block;
  width: 100%;
  height: 36px;
  padding: 0 20px;
  background: #fff;
  color: #323840;
  border: 1px solid #3d85d8;
  border-radius: 4px;
  box-sizing: border-box;
  &:focus {
    outline: none;
    ~ .did-floating-label {
      top: -8px;
      font-size: 13px;
    }
  }
}

select.did-floating-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select.did-floating-select::-ms-expand {
  display: none;
}

.did-floating-input:not(:placeholder-shown) ~ .did-floating-label {
  top: -8px;
  font-size: 13px;
}
.did-floating-select:not([value=""]):valid ~ .did-floating-label {
  top: -8px;
  font-size: 13px;
}
.did-floating-select[value=""]:focus ~ .did-floating-label {
  top: 11px;
  font-size: 13px;
}
.did-floating-select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
  background-position: right 15px top 50%;
  background-repeat: no-repeat;
}

.did-error-input {
  .did-floating-input,
  .did-floating-select {
    border: 2px solid #9d3b3b;
    color: #9d3b3b;
  }
  .did-floating-label {
    font-weight: 600;
    color: #9d3b3b;
  }
  .did-floating-select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%239d3b3b'/%3E%3C/svg%3E%0A");
  }
}
.editProfilePage {
  .modal-content {
    border-radius: 30px;
  }
  .modal-content,
  .modal-body {
    padding: 0;
  }
  .modal-header {
    padding: 0;
  }
  .modal-header {
    .modal-title {
      display: none;
    }
  }
  .scoreListingOuter {
    padding: 10px;
  }
  .myScore {
    flex-basis: 55%;
    padding: 2rem;
    padding-right: 90px;
    @media (max-width: 990px) {
      flex-basis: 100%;
      padding: 0rem;
    }
    h2 {
      color: #960396;
      font-size: 28px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      @media (max-width: 990px) {
        font-size: 16px;
        padding: 10px;
        margin-bottom: 10px;
        max-width: 88%;
      }
    }
  }
  .scoreList {
    border-radius: 85px;
    @extend.gradientgreen;
    padding: 5px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $white;
    font-size: 16px;
    margin-bottom: 20px;
    p {
      font-weight: 600;
      @media (max-width: 990px) {
        max-width: 90%;
        margin: 0 auto;
        font-size: 14px;
        text-align: center;
      }
    }
    .userImage {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #fff;
    }
    .points {
      font-size: 26px;
      font-weight: 600;
      padding-right: 0.5rem;
    }
  }
  .editFormSec {
    background-color: #f1f3f4;
    flex-basis: 45%;
    border-radius: 20px;
    min-height: 100vh;
    max-width: 640px;

    @media (max-width: 990px) {
      flex-basis: 75%;
    }

    @media (max-width: 767px) {
      min-height: inherit;
    }

    .formSec {
      margin-top: 4rem;
      padding: 1rem;
      @media (max-width: 1536px) {
        margin-top: 2rem;
      }
      .form-floating > .form-select {
        padding-top: 0.525rem;
      }
      .form-select:focus {
        border-color: #333333;
        box-shadow: none;
      }
      .form-control,
      .form-select {
        background-color: transparent;
        border-radius: 10px;
        color: #6f6f6f;
      }
      .form-floating > label {
        padding-top: 1.2rem;
      }
      .form-floating > .form-control-plaintext ~ label::after,
      .form-floating > .form-control:focus ~ label::after,
      .form-floating > .form-control:not(:placeholder-shown) ~ label::after,
      .form-floating > .form-select ~ label::after {
        background-color: #f1f3f4;
      }
    }
  }

  .shapeBox {
    // @extend.gradientgreen;
    background: url(../img/green_box.svg) no-repeat;
    background-position: center;
    padding: 25px;
    display: flex;
    justify-content: center;
    position: relative;
    border-radius: 20px;
    min-height: 190px;
    align-items: flex-start;

    @media (max-width: 767px) {
      border-radius: unset;
    }

    .headingMain {
      display: inline-flex;
      color: #426d2e;
      background: $white;
      font-weight: bold;
      &::after,
      &::before {
        background: url(../img/line-shape-white.svg) no-repeat 0 0;
      }
    }
  }

  .profileImage {
    position: absolute;
    bottom: -40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 2px solid #5b8847;
    background-color: #f1f3f4;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .editIcon {
      position: absolute;
      right: -8px;
      bottom: 50px;
      background: url(../img/edit-icon.png) no-repeat 0 0;
      background-position: center center;
      background-size: 60% auto;
      width: 30px;
      height: 30px;
      padding: 0;
      background-color: #3c6729;
      border-radius: 50%;
    }
  }

  @media (max-width: 767px) {
    .customScroll .view.small {
      max-height: 350px !important;
    }
  }
}

.editPageOuter {
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}

// landscape view  css
@media (max-width: 900px) {
  @media screen and (orientation: landscape) {
    .modal-content {
      padding: 0 0.2rem 0.2rem;
    }
    .modal-content .modal-header {
      padding-top: 1rem;
    }
    .membersLoungePage .hotspots .hotspot-item {
      &.hotspot-item-1 {
        top: 60%;
        left: 3%;
      }

      &.hotspot-item-2 {
        top: 57.5%;
        left: 12%;
      }

      &.hotspot-item-3 {
        top: 56%;
        left: 18.4%;
      }
      &.hotspot-item-4 {
        top: 54.2%;
        left: 23.5%;
      }
      &.hotspot-item-5 {
        top: 53.5%;
      }
    }
    .conatctModal .getintouch:after,
    .conatctModal .getintouch:before {
      background-size: auto 80%;
    }
  }
  .customScroll .view {
    max-height: 180px !important;
  }
  .topUserListing {
    max-height: 50%;
    overflow-y: auto;
  }
}
@media (max-width: 575px) {
  .wrapper {
    overflow-y: auto;
  }
  @media screen and (orientation: portrait) {
    .imageOuter {
      min-width: 900px;
      img {
        aspect-ratio: 9/16;
      }
    }
    .hotspots {
      min-width: 900px;
    }
  }
}

@media (min-width: 767px) and (max-width: 1000px) {
  .modal {
    --bs-modal-width: 700px;
  }
  .topPerformers .pointsTable {
    margin: 0;
  }
  .topPerformers .performerPlayer .plyerName {
    font-size: 12px;
    padding: 0.5rem;
  }
  .topPerformers .pointsTable ul li span {
    font-size: 1.2rem;
  }
}

@media (max-width: 767px) {
  // .modal-dialog {
  //   margin-top: 60px;
  // }
  .conatctModal.modalPages .btn-close {
    right: 20px;
    top: 20px;
  }
}

.squadPage {
  background: url(../img/team-squad-bg.jpg) no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  width: 100%;
  overflow-x: hidden;
  flex-direction: column;

  justify-content: center;

  @media (max-width: 767px) {
    padding: 7rem 0;
  }

  .slick-slide img {
    margin: 0 auto;
    @media (min-width: 1537px) {
      width: 100%;
    }
    @media (max-width: 767px) {
      max-width: 296px;
    }
  }
  .playerImg {
    @media (max-width: 767px) {
      max-width: inherit;
    }
  }
  .playerCol {
    position: relative;
    .playerDescp {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 1rem 0 0 0;
      @media (max-width: 990px) {
        padding-top: 6px;
      }
      @media (max-width: 767px) {
        padding-top: 10px;
      }

      .playerName {
        background: #a21ea6;
        -webkit-transform: skew(-355deg);
        transform: skew(-355deg);
        max-width: 70%;
        margin: 0 auto;

        padding: 0.5rem 1rem;
        min-height: 5rem;
        display: flex;

        align-items: center;
        justify-content: center;
        @media (max-width: 1536px) {
          min-height: 4rem;
        }
        @media (max-width: 990px) {
          min-height: 3rem;
        }
        @media (max-width: 767px) {
          max-width: 200px;
          min-height: 62.06px;
        }

        h3 {
          text-transform: uppercase;
          -webkit-transform: skew(355deg);
          transform: skew(355deg);
          color: $white;
          font-size: 1.4rem;
          text-align: center;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          @media (max-width: 1536px) {
            font-size: 1rem;
          }
          @media (max-width: 990px) {
            font-size: 0.8rem;
          }
          @media (max-width: 767px) {
            font-size: 1.2rem;
          }
        }
      }

      .playerPostion {
        font-size: 1.2rem;
        color: $green;
        text-align: center;
        font-weight: bold;
        padding-top: 10px;
        text-transform: uppercase;
        @media (max-width: 1536px) {
          padding-top: 5px;
        }
        @media (max-width: 990px) {
          font-size: 0.8rem;
        }
        @media (max-width: 767px) {
          font-size: 1.3rem;
        }
      }
      .playerPicture {
        padding-top: 3.5rem;
        display: block;
        @media (max-width: 1980px) {
          padding-top: 3.3rem;
        }
        @media (max-width: 1880px) {
          padding-top: 2.4rem;
        }

        @media (max-width: 1536px) {
          padding-top: 2.8rem;
        }
        @media (max-width: 1436px) {
          padding-top: 2.1rem;
        }
        @media (max-width: 996px) {
          padding-top: 3.6rem;
        }
        @media (max-width: 845px) {
          padding-top: 3.1rem;
        }
        @media (max-width: 767px) {
          padding-top: 2.2rem;
        }
      }
    }
  }
}
.playerDetailsOuter {
  padding: 30px;
  display: flex;
  @media (max-width: 1536px) {
    padding: 0;
  }
  width: 100%;
  flex: 1;
  @media (max-width: 800px) {
    flex-direction: column;
  }
  .playerInfo {
    @extend.gradientgreen;
    max-width: 440px;
    min-width: 440px;
    padding: 30px;
    border-radius: 6px;
    @media (max-width: 990px) {
      min-width: 300px;
    }
    @media (max-width: 800px) {
      min-width: 100%;
    }
    .playerImageSec {
      position: relative;
      display: block;
      text-align: center;
      margin-bottom: 40px;
    }
    .playerName {
      background: $white;
      border-radius: 4px;
      display: inline-block;
      padding: 10px 20px;
      color: #aa3e97;
      min-width: 50%;
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: bold;
    }
    .playerinformation {
      background-color: rgba(255, 255, 255, 0.19);
      margin-bottom: 10px;
      display: flex;
      border-radius: 4px;
      padding: 8px 15px;
      color: $white;
      font-weight: 600;
      justify-content: space-between;
    }
  }
  .playerStatsDetails {
    padding: 0 3rem;
    width: 100%;
    @media (max-width: 1536px) {
      padding: 0 2rem;
    }
    @media (max-width: 767px) {
      padding: 0;
    }
  }
  .playerStatsHeader {
    display: flex;
    position: relative;
    margin-bottom: 50px;
    justify-content: space-between;
    @media (max-width: 990px) {
      justify-content: space-between;
      margin-top: 30px;
      align-items: center;
      .filterSec {
        margin-bottom: 0;
        right: 0;
      }
    }
    @media (max-width: 767px) {
      flex-direction: column;
      h1 {
        margin-bottom: 20px;
      }
    }
    .titleText {
      font-size: 1.8rem;
      color: #960396;
      font-weight: 600;
      @media (max-width: 767px) {
        font-size: 1.2rem;
      }
    }
  }

  .blackheader {
    background: url(../img/black-pattern.jpg) no-repeat;
    background-size: cover;
    padding: 3rem 2rem 2rem 2rem;
    width: 100%;
    color: $white;
    border-radius: 20px;
    font-size: 3.4rem;
    font-weight: 600;
    margin-bottom: 50px;
    @media (max-width: 767px) {
      font-size: 2.8rem;
    }

    p {
      font-size: 18px;
      font-weight: normal;
    }
    .bdrRight {
      border-right: 1px solid rgba(255, 255, 255, 0.5);
      @media (max-width: 990px) {
        border-right: none;
        margin-bottom: 20px;
      }
    }
  }
}

.dataDetails {
  border: 1px solid #c9d8c3;
  border-radius: 10px;
  padding: 1.2rem 1rem;
  margin-top: 30px;
  .attackingData {
    text-align: center;
    font-size: 1.8rem;
    color: #272727;
    font-weight: bold;
    p {
      font-size: 18px;
      font-weight: normal;
    }
  }
  [class*="col-"] {
    border-right: 1px solid rgba(126, 126, 126, 0.5);
    @media (max-width: 767px) {
      border-right: none;
    }
    &:last-child {
      border-right: none;
    }
  }
}

.tableOuter {
  // background: url(../img/led.jpg) no-repeat;
  background: #000000;
  border-radius: 5px;
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.04),
    0 1px 0 rgba(255, 255, 255, 0.15) inset;
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.04),
    0 1px 0 rgba(255, 255, 255, 0.15) inset;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.04),
    0 1px 0 rgba(255, 255, 255, 0.15) inset;
  border: 1px solid #000000;
  background-clip: padding-box;
  max-width: 90%;
  margin: 0 auto;
  background-size: contain;
  background-position: center top;
  position: relative;
  padding: 10px;
  @media (max-width: 1175px) {
    max-width: 100%;
  }
  @media (max-width: 575px) {
    padding: 4px;
  }
  // padding: 1.5rem 1.8rem;
  // padding-bottom: 5rem;
  .display-shine {
    position: absolute;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjEuMCIgeDI9IjAuNSIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwLjA1Ii8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==);
    background-size: 100%;
    background-image: -webkit-gradient(
      linear,
      50% 100%,
      50% 0%,
      color-stop(0%, rgba(255, 255, 255, 0.05)),
      color-stop(100%, rgba(255, 255, 255, 0))
    );
    background-image: -moz-linear-gradient(
      bottom,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0)
    );
    background-image: -webkit-linear-gradient(
      bottom,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0)
    );
    background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0)
    );
    width: 100%;
    height: 50%;
    pointer-events: none;
  }
  .display-monitor {
    position: absolute;
    background: transparent
      url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAYAAACZgbYnAAAAEElEQVQIW2NkYGBQYwQSDAAA/QApT6IXSwAAAABJRU5ErkJggg==);
    width: 98.5%;
    height: 98.5%;
  }
  .display {
    background-color: rgba(35, 13, 80, 0.23);
    -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.53);
    -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.53);
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.53);
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzFmMDkyNiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzEwMDQyNCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==);
    background-size: 100%;
    background-image: -webkit-gradient(
      linear,
      50% 0%,
      50% 100%,
      color-stop(0%, #fff),
      color-stop(100%, #efefef)
    );
    background-image: -moz-linear-gradient(top, #fff, #efefef);
    background-image: -webkit-linear-gradient(top, #fff, #efefef);
    background-image: linear-gradient(to bottom, #fff, #efefef);
    margin: 10px;
    padding: 10px;
    text-align: center;
    @media (max-width: 767px) {
      overflow-x: auto;
    }
  }

  &::after {
    content: "";
    // display: block;
    // position: absolute;
    // left: 0;
    // width: 100%;
    // height: 100%;
    // background-color: rgba(0, 0, 0, 0.49);
    top: 0;
  }
  .table-responsive {
    background-color: transparent;
    position: relative;

    z-index: 1;
    td {
      background-color: transparent;
      // color: #fff;
    }

    @media (max-width: 767px) {
      tr td {
        &:nth-child(2) {
          min-width: 150px;
        }
      }
    }
  }
}

.topUserListing {
  background-color: #fff;
  min-width: 25%;
  // min-height: 25%;
  position: absolute;
  left: 37.5%;
  top: 31.5%;
  border: 10px solid;
  border-image-slice: 1;

  border-image-source: linear-gradient(to left, #5e3308, #2a1301);
  .top-leaderborad {
    background: #aa3e97;
    padding: 10px 0;
    margin: 0;
    // margin-bottom: 1px;
    font-weight: bold;
    color: $white;
    font-size: 12px;
  }

  .top-leaderName {
    font-size: 11px;
    font-weight: 500;
    text-align: center;
  }

  .leaderborad-listing {
    background: #1e5341;
    margin: 0;
    color: $white;
    padding: 10px 0;
    font-size: 12px;
    &:nth-child(even) {
      background: #002a1c;
    }
    img {
      height: auto;
      max-height: 30px;
      width: auto;
    }
    .leaderpic,
    .leadername {
      display: inline-flex;
      align-items: center;
    }
    .userListing {
      display: flex;
      width: 100%;
      align-items: center;
      font-weight: 500;
    }
    .leadername2 {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .leaderborad-sr {
    position: relative;

    z-index: 9;
    color: #000;
    font-weight: bold;
    padding: 5px 0px;
    &:after {
      position: absolute;
      left: -12px;
      top: -10px;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 34px 34px 0 0;
      border-color: #f8df25 transparent transparent transparent;
      z-index: -1;

      @media (max-width: 1600px) {
        top: -5px;
        left: -17px;
      }
    }

    .rankCount {
      position: absolute;
      top: -8px;
      left: -5px;

      @media (max-width: 1600px) {
        top: -3px;
        left: -10px;
      }
    }
  }
}

.quizSec {
  background: url(../img/quiz-bg.jpg) no-repeat;
  background-size: cover;
  position: relative;
  padding: 90px 70px;
  border-radius: 20px;
  min-height: 625px;
  max-width: 80%;
  margin: 0 auto;
  position: relative;
  margin-top: 8rem;
  @media (max-width: 1536px) {
    min-height: inherit;
    padding-bottom: 20px;
    max-width: 100%;
    margin-top: 5rem;
  }
  @media (max-width: 767px) {
    max-width: 100%;
    padding: 20px;
  }
  h2 {
    color: $white;
    font-size: 2rem;
    margin-bottom: 4rem;
    @media (max-width: 767px) {
      font-size: 1.6rem;
    }
  }
  .form-group {
    margin-bottom: 3rem;
    label {
      color: $white;
    }
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 20px 28px 16px 48px;
    min-width: 300px;
    font-size: 1rem;
    border-radius: 60px;
    @media (max-width: 767px) {
      min-width: 100%;
    }
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 20px;
    top: 21px;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    // background: #fff;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 12px;
    height: 12px;
    background: #fff;
    position: absolute;
    top: 24px;
    left: 23px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  [type="radio"]:checked + label {
    background: transparent
      linear-gradient(270deg, #639250 0%, var(--unnamed-color-386224) 100%) 0%
      0% no-repeat padding-box;
    background: transparent linear-gradient(270deg, #639250 0%, #386224 100%) 0%
      0% no-repeat padding-box;
  }
  .text-right {
    text-align: ce;
  }
  .manImage {
    img {
      position: absolute;
      top: -15rem;
      max-height: 850px;
      right: 6rem;
      @media (max-width: 1536px) {
        max-height: 700px;
        right: 2rem;
        top: -10rem;
      }
      @media (max-width: 990px) {
        display: none;
      }
    }
  }
}
@media (max-width: 990px) {
  .pagination {
    background: #fff;
  }
}
@media (max-width: 767px) {
  .modalPages .btn-close {
    width: 24px;
    height: 24px;
  }
}

@media only screen and (max-width: 667px) and (orientation: landscape) {
  .framephotoboothOuter .framephotobooth {
    width: 100%;
  }
}
#warning-message {
  display: none;
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .main-wrapper,
  .container {
    display: none;
  }
  #warning-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    div {
      text-align: center;
    }
    p {
      font-weight: bold;
    }
  }
}
