@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap");
.gradientgreen, .playerDetailsOuter .playerInfo, .editProfilePage .scoreList, .topPerformers .performerPlayer .plyerName, .topPerformers .pointsTable, .resultMatchs .resultTitle, .gallerySeasoModal .season-list-item.media-listing .overlayList, .gallerySeasoModal .season-list-item .matchNumb, .gallerySeasoModal .season-list-item .overlayList .view, .gallerySeasoModal .nav-tabs .nav-link.active, .aboutModal .followUsOn, .loginPage .nav-tabs .nav-link.active::after, .conatctModal .getintouch, .modalPages h1,
.modalPages .headingMain, .footer, .hotspots .hotspot-item a, .btn {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#386224+0,639250+100 */
  background: rgb(56, 98, 36); /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgb(56, 98, 36) 0%, rgb(99, 146, 80) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#386224", endColorstr="#639250",GradientType=1 ); /* IE6-9 */
}

.gradientHover {
  background: rgb(56, 98, 36); /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgb(56, 98, 36) 0%, rgb(230, 204, 103) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#386224", endColorstr="#e6cc67",GradientType=1 ); /* IE6-9 */
}

* {
  box-sizing: border-box;
}

body {
  padding: 0px;
  font-family: "Poppins", sans-serif;
  margin: 0px;
  overflow-x: hidden;
  min-height: 100%;
  overflow-y: auto;
}
body.hiddenscroll {
  overflow: hidden;
}

@media (min-width: 1536px) {
  .modal-xl {
    --bs-modal-width: 1320px;
  }
}
ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  max-width: 100%;
}

body,
html {
  padding: 0px;
  width: 100%;
  margin: 0px;
}

ul,
ol,
li,
a,
span,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0px;
  margin: 0px;
  outline: none;
  text-decoration: none;
}

.oswald, .gallerySeasoModal .season-list-item .matchNumb, .teamStanding .seasonPostion p, .aboutModal .followUsOn .count, .loginPage .nav-tabs, .sponsorLogos h4, .btn, h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Oswald", sans-serif;
}

.borderR5 {
  border-radius: 5px;
}

.borderR10 {
  border-radius: 10px;
}

.conatctModal .getintouch p span, .conatctModal .getintouch h3, .conatctModal .getintouch h2 {
  font-weight: 500;
}

.topPerformers .performerPlayer .plyerName, .topPerformers h2, .modalPages h1,
.modalPages .headingMain {
  font-weight: bold;
}

.gallerySeasoModal .season-list-item .overlayList, .gallerySeasoModal .season-list-item, .loginPage .nav-tabs .nav-link::after, .footer ul li span, .footer ul li {
  transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
}

a:hover {
  text-decoration: none;
}

p {
  line-height: normal;
  font-weight: normal;
}

.mT5 {
  margin-top: 5px;
}

.mB5 {
  margin-bottom: 5px;
}

.mR5 {
  margin-right: 5px;
}

.mL5 {
  margin-left: 5px;
}

.mT10 {
  margin-top: 10px;
}

.mB10 {
  margin-bottom: 10px;
}

.mR10 {
  margin-right: 10px;
}

.mL10 {
  margin-left: 10px;
}

.mT15 {
  margin-top: 15px;
}

.mB15 {
  margin-bottom: 15px;
}

.mR15 {
  margin-right: 15px;
}

.mL15 {
  margin-left: 15px;
}

.mT20 {
  margin-top: 20px;
}

.mB20 {
  margin-bottom: 20px;
}

.mR20 {
  margin-right: 20px;
}

.mL20 {
  margin-left: 20px;
}

.mT25 {
  margin-top: 25px;
}

.mB25 {
  margin-bottom: 25px;
}

.mR25 {
  margin-right: 25px;
}

.mL25 {
  margin-left: 25px;
}

.mT30 {
  margin-top: 30px;
}

.mB30 {
  margin-bottom: 30px;
}

.mR30 {
  margin-right: 30px;
}

.mL30 {
  margin-left: 30px;
}

.mT35 {
  margin-top: 35px;
}

.mB35 {
  margin-bottom: 35px;
}

.mR35 {
  margin-right: 35px;
}

.mL35 {
  margin-left: 35px;
}

.mT40 {
  margin-top: 40px;
}

.mB40 {
  margin-bottom: 40px;
}

.mR40 {
  margin-right: 40px;
}

.mL40 {
  margin-left: 40px;
}

.mT42 {
  margin-top: 42px;
}

.mB42 {
  margin-bottom: 42px;
}

.mR42 {
  margin-right: 42px;
}

.mL42 {
  margin-left: 42px;
}

.mT45 {
  margin-top: 45px;
}

.mB45 {
  margin-bottom: 45px;
}

.mR45 {
  margin-right: 45px;
}

.mL45 {
  margin-left: 45px;
}

.mT50 {
  margin-top: 50px;
}

.mB50 {
  margin-bottom: 50px;
}

.mR50 {
  margin-right: 50px;
}

.mL50 {
  margin-left: 50px;
}

.mT55 {
  margin-top: 55px;
}

.mB55 {
  margin-bottom: 55px;
}

.mR55 {
  margin-right: 55px;
}

.mL55 {
  margin-left: 55px;
}

.mT60 {
  margin-top: 60px;
}

.mB60 {
  margin-bottom: 60px;
}

.mR60 {
  margin-right: 60px;
}

.mL60 {
  margin-left: 60px;
}

.mT65 {
  margin-top: 65px;
}

.mB65 {
  margin-bottom: 65px;
}

.mR65 {
  margin-right: 65px;
}

.mL65 {
  margin-left: 65px;
}

.mT70 {
  margin-top: 70px;
}

.mB70 {
  margin-bottom: 70px;
}

.mR70 {
  margin-right: 70px;
}

.mL70 {
  margin-left: 70px;
}

.mT75 {
  margin-top: 75px;
}

.mB75 {
  margin-bottom: 75px;
}

.mR75 {
  margin-right: 75px;
}

.mL75 {
  margin-left: 75px;
}

.mT80 {
  margin-top: 80px;
}

.mB80 {
  margin-bottom: 80px;
}

.mR80 {
  margin-right: 80px;
}

.mL80 {
  margin-left: 80px;
}

.mT85 {
  margin-top: 85px;
}

.mB85 {
  margin-bottom: 85px;
}

.mR85 {
  margin-right: 85px;
}

.mL85 {
  margin-left: 85px;
}

.mT90 {
  margin-top: 90px;
}

.mB90 {
  margin-bottom: 90px;
}

.mR90 {
  margin-right: 90px;
}

.mL90 {
  margin-left: 90px;
}

.mT99 {
  margin-top: 99px;
}

.mB99 {
  margin-bottom: 99px;
}

.mR99 {
  margin-right: 99px;
}

.mL99 {
  margin-left: 99px;
}

.pT5 {
  padding-top: 5px;
}

.pB5 {
  padding-bottom: 5px;
}

.pR5 {
  padding-right: 5px;
}

.pL5 {
  padding-left: 5px;
}

.pT10 {
  padding-top: 10px;
}

.pB10 {
  padding-bottom: 10px;
}

.pR10 {
  padding-right: 10px;
}

.pL10 {
  padding-left: 10px;
}

.pT15 {
  padding-top: 15px;
}

.pB15 {
  padding-bottom: 15px;
}

.pR15 {
  padding-right: 15px;
}

.pL15 {
  padding-left: 15px;
}

.pT20 {
  padding-top: 20px;
}

.pB20 {
  padding-bottom: 20px;
}

.pR20 {
  padding-right: 20px;
}

.pL20 {
  padding-left: 20px;
}

.pT25 {
  padding-top: 25px;
}

.pB25 {
  padding-bottom: 25px;
}

.pR25 {
  padding-right: 25px;
}

.pL25 {
  padding-left: 25px;
}

.pT30 {
  padding-top: 30px;
}

.pB30 {
  padding-bottom: 30px;
}

.pR30 {
  padding-right: 30px;
}

.pL30 {
  padding-left: 30px;
}

.pT35 {
  padding-top: 35px;
}

.pB35 {
  padding-bottom: 35px;
}

.pR35 {
  padding-right: 35px;
}

.pL35 {
  padding-left: 35px;
}

.pT40 {
  padding-top: 40px;
}

.pB40 {
  padding-bottom: 40px;
}

.pR40 {
  padding-right: 40px;
}

.pL40 {
  padding-left: 40px;
}

.pT42 {
  padding-top: 42px;
}

.pB42 {
  padding-bottom: 42px;
}

.pR42 {
  padding-right: 42px;
}

.pL42 {
  padding-left: 42px;
}

.pT45 {
  padding-top: 45px;
}

.pB45 {
  padding-bottom: 45px;
}

.pR45 {
  padding-right: 45px;
}

.pL45 {
  padding-left: 45px;
}

.pT50 {
  padding-top: 50px;
}

.pB50 {
  padding-bottom: 50px;
}

.pR50 {
  padding-right: 50px;
}

.pL50 {
  padding-left: 50px;
}

.pT55 {
  padding-top: 55px;
}

.pB55 {
  padding-bottom: 55px;
}

.pR55 {
  padding-right: 55px;
}

.pL55 {
  padding-left: 55px;
}

.pT60 {
  padding-top: 60px;
}

.pB60 {
  padding-bottom: 60px;
}

.pR60 {
  padding-right: 60px;
}

.pL60 {
  padding-left: 60px;
}

.pT65 {
  padding-top: 65px;
}

.pB65 {
  padding-bottom: 65px;
}

.pR65 {
  padding-right: 65px;
}

.pL65 {
  padding-left: 65px;
}

.pT70 {
  padding-top: 70px;
}

.pB70 {
  padding-bottom: 70px;
}

.pR70 {
  padding-right: 70px;
}

.pL70 {
  padding-left: 70px;
}

.pT75 {
  padding-top: 75px;
}

.pB75 {
  padding-bottom: 75px;
}

.pR75 {
  padding-right: 75px;
}

.pL75 {
  padding-left: 75px;
}

.pT80 {
  padding-top: 80px;
}

.pB80 {
  padding-bottom: 80px;
}

.pR80 {
  padding-right: 80px;
}

.pL80 {
  padding-left: 80px;
}

.pT85 {
  padding-top: 85px;
}

.pB85 {
  padding-bottom: 85px;
}

.pR85 {
  padding-right: 85px;
}

.pL85 {
  padding-left: 85px;
}

.pT90 {
  padding-top: 90px;
}

.pB90 {
  padding-bottom: 90px;
}

.pR90 {
  padding-right: 90px;
}

.pL90 {
  padding-left: 90px;
}

.pT99 {
  padding-top: 99px;
}

.pB99 {
  padding-bottom: 99px;
}

.pR99 {
  padding-right: 99px;
}

.pL99 {
  padding-left: 99px;
}

.wrapper {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.wrapper .videoOuter {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100%;
}
.wrapper .videoOuter video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.btn {
  display: inline-flex;
  width: 100px;
  border-radius: 10px;
  text-align: center;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: none;
  z-index: 11;
  bottom: 10%;
  left: 0;
  right: 0;
  margin: 0 auto;
  cursor: pointer;
}
.btn.skip {
  position: fixed;
}
.btn.send {
  font-size: 18px;
  font-weight: 600;
  min-width: 155px;
}
@media (max-width: 767px) {
  .btn.send {
    min-width: 120px;
    margin: 0 10px;
  }
}
.btn:hover { /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgb(56, 98, 36) 0%, rgb(230, 204, 103) 100%);
  color: #fff;
}

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 111;
}
.header .notifaction {
  margin-right: 10px;
}

.imageOuter {
  height: 100vh;
}
.imageOuter img {
  width: 100%;
  height: 100%;
}

.hotspots {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  z-index: 100;
}

.hotspots .hotspot-item {
  position: absolute;
  font-size: 14px;
  font-weight: 500;
}
@media (max-width: 1366px) {
  .hotspots .hotspot-item {
    font-size: 12px;
  }
}
.hotspots .hotspot-item a {
  min-height: 30px;
  border-radius: 6px;
  padding-right: 10px;
  padding-left: 20px;
  color: #fff;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hotspots .hotspot-item.hotspot-item-1 {
  left: 11%;
  top: 26.5%;
}
.hotspots .hotspot-item.hotspot-item-2 {
  left: 31%;
  top: 30%;
}
.hotspots .hotspot-item.hotspot-item-3 {
  left: 48%;
  top: 30%;
}
.hotspots .hotspot-item.hotspot-item-4 {
  left: 66%;
  top: 30%;
}
.hotspots .hotspot-item.hotspot-item-5 {
  left: 83%;
  top: 24%;
}
.hotspots .hotspot-item.hotspot-item-6 {
  left: 39.5%;
  top: 48.5%;
}
.hotspots .hotspot-item.hotspot-item-7 {
  left: 55%;
  top: 48.5%;
}
.hotspots .hotspot-item.hotspot-item-8 {
  left: 31.2%;
  top: 54.8%;
}
.hotspots .hotspot-item.create-team {
  right: 15%;
  top: 30%;
  left: auto;
}
.hotspots .hotspot-item.hotspot-item-9 {
  left: 48%;
  top: 58.8%;
}
.hotspots .hotspot-item.hotspot-item-10 {
  left: 62.2%;
  top: 63%;
}
.hotspots .hotspot-item.hotspot-item-11 {
  left: 63.6%;
  top: 69.5%;
}
.hotspots .hotspot-item .arrow_box {
  border: none;
  position: absolute;
  bottom: 1px;
}
.hotspots .hotspot-item .arrow_box:after,
.hotspots .hotspot-item .arrow_box:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.hotspots .hotspot-item .arrow_box:after {
  border-color: rgba(88, 120, 65, 0);
  border-top-color: #587841;
  border-width: 9px;
  margin-left: -15px;
}
.hotspots .hotspot-item .arrow_box:before {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 10px;
  margin-left: -16px;
}
@keyframes action {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}
.pulsating-circle {
  position: absolute;
  left: 10px;
  top: 52.6%;
  transform: translateX(-50%) translateY(-50%);
  width: 8px;
  height: 8px;
}
.pulsating-circle:before {
  content: "";
  position: relative;
  display: block;
  width: 200%;
  height: 200%;
  box-sizing: border-box;
  margin-left: -50%;
  margin-top: -50%;
  border-radius: 45px;
  background-color: #fff;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}
.pulsating-circle:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%, 100% {
    opacity: 0;
  }
}
@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 15px 0;
  z-index: 101;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#386224+0,639250+100 */
  background: rgb(56, 98, 36); /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(56, 98, 36, 0.9) 0%, rgba(99, 146, 80, 0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#386224", endColorstr="#639250",GradientType=1 ); /* IE6-9 */
}
.footer ul {
  display: flex;
  justify-content: space-around;
  align-content: center;
  margin-bottom: 0;
  padding: 0 3rem;
  padding-bottom: 0;
}
@media (max-width: 1535px) {
  .footer ul {
    padding: 0 2rem;
    padding-bottom: 0px;
  }
}
@media (max-width: 990px) {
  .footer ul {
    padding: 0;
  }
}
.footer ul li {
  display: inline-flex;
  padding: 0 1rem;
  flex-direction: column;
  justify-content: flex-end;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  position: relative;
}
@media (max-width: 1535px) {
  .footer ul li {
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
@media (max-width: 1366px) {
  .footer ul li {
    font-size: 13px;
    white-space: nowrap;
  }
  .footer ul li img {
    max-height: 24px;
  }
}
.footer ul li span {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer ul li span:hover {
  opacity: 0.8;
}

.footerLinks {
  display: flex;
  justify-content: flex-end;
}
.footerLinks span {
  color: #fff;
  font-size: 14px;
  padding: 0 20px;
  position: relative;
}
.footerLinks span::after {
  content: "|";
  position: absolute;
  right: 0;
}
.footerLinks span:last-child::after {
  display: none;
}

.modalPages .modal-header {
  border-bottom: none;
  padding-left: 3rem;
  justify-content: center;
}
@media (max-width: 767px) {
  .modalPages .modal-header {
    padding-left: 2.3rem;
  }
}
.modalPages .btn-close {
  background: #578544;
  border-radius: 50%;
  color: #fff;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  opacity: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  right: 20px;
  top: 20px;
  width: 40px;
  height: 40px;
  z-index: 11;
}
@media (max-width: 767px) {
  .modalPages .btn-close {
    right: 20px;
  }
}
.modalPages .btn-close:focus {
  outline: none;
  box-shadow: none;
}
.modalPages h1,
.modalPages .headingMain {
  color: #fff;
  font-size: 20px;
  padding: 13px 20px;
  min-height: 54px;
  font-weight: bold;
  transform: skew(345deg);
  position: relative;
}
@media (max-width: 1536px) {
  .modalPages h1,
  .modalPages .headingMain {
    padding: 13px 20px;
  }
}
.modalPages h1::after,
.modalPages .headingMain::after {
  content: "";
  display: block;
  background: url(../img/line-shape.svg) no-repeat 0 0;
  width: 33px;
  height: 56px;
  position: absolute;
  left: -36px;
  top: 0px;
  transform: skew(15deg);
}
.modalPages h1::before,
.modalPages .headingMain::before {
  content: "";
  display: block;
  background: url(../img/line-shape.svg) no-repeat 0 0;
  width: 33px;
  height: 56px;
  position: absolute;
  right: -36px;
  top: 0px;
  transform: skew(15deg);
}
.modalPages h1 span,
.modalPages .headingMain span {
  display: block;
  transform: skew(15deg);
}
@media (min-width: 1160px) {
  .modalPages h1 span,
  .modalPages .headingMain span {
    min-width: 100px;
    text-align: center;
  }
}
.modalPages .modal-content {
  border-radius: 0;
  background: url(../img/sponsord-background.png) no-repeat;
  background-size: cover;
}

.sponsorLogos {
  background: #fff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.0509803922);
  border-radius: 10px;
  min-height: 250px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 990px) {
  .sponsorLogos {
    max-width: 140px;
    margin: 0 auto;
    margin-bottom: 30px;
    min-height: inherit;
    padding: 10px;
  }
}
.sponsorLogos h4 {
  font-size: 19px;
  color: #4a5745;
  margin-top: 15px;
  font-weight: normal;
}

.contactOuter {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  height: 100%;
}

.conatctModal h1 {
  background: #fff;
  color: #386224;
}
.conatctModal h1::before,
.conatctModal .headingMain::before,
.conatctModal h1::after,
.conatctModal .headingMain::after {
  background: url(../img/line-shape-white.svg) no-repeat 0 0;
}
.conatctModal .modal-content {
  padding: 0;
}
.conatctModal .getintouch {
  width: 100%;
  min-height: 100%;
  padding: 2rem 1.5rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.conatctModal .getintouch div {
  position: relative;
  z-index: 1;
  text-align: center;
  max-width: 480px;
}
.conatctModal .getintouch:after {
  content: "";
  display: block;
  width: 98%;
  height: 100%;
  position: absolute;
  bottom: 0;
  background-image: url(../img/p2-left.png);
  background-repeat: no-repeat;
  background-position: 100% top;
}
@media (max-width: 1536px) {
  .conatctModal .getintouch:after {
    background-size: auto 100%;
  }
}
@media (max-width: 767px) {
  .conatctModal .getintouch:after {
    background-size: 35% auto;
    background-position: bottom right;
  }
}
.conatctModal .getintouch:before {
  content: "";
  display: block;
  width: 98%;
  height: 100%;
  position: absolute;
  bottom: 0;
  background-image: url(../img/p2.png);
  background-repeat: no-repeat;
  background-position: 0% top;
}
@media (max-width: 1536px) {
  .conatctModal .getintouch:before {
    background-size: auto 100%;
  }
}
@media (max-width: 767px) {
  .conatctModal .getintouch:before {
    background-size: 35% auto;
    background-position: bottom left;
  }
}
@media (max-width: 767px) {
  .conatctModal .getintouch {
    width: 100%;
    flex-wrap: nowrap;
  }
}
.conatctModal .getintouch .socailIcons {
  margin-top: 30px;
  text-align: center;
}
.conatctModal .getintouch .socailIcons a {
  padding: 0 0.5rem;
}
.conatctModal .getintouch .socailIcons a:last-child {
  padding: 0;
}
.conatctModal .getintouch address {
  margin-top: 40px;
  font-size: 18px;
}
@media (max-height: 900px) {
  .conatctModal .getintouch address {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .conatctModal .getintouch address {
    font-size: 14px;
  }
}
.conatctModal .getintouch h2 {
  font-size: 38px;
  margin-bottom: 10px;
  align-items: center;
}
@media (max-width: 1536px) {
  .conatctModal .getintouch h2 {
    font-size: 20px;
  }
}
.conatctModal .getintouch h2 img {
  margin-right: 5px;
}
.conatctModal .getintouch h3 {
  font-size: 28px;
  justify-content: center;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
@media (max-width: 1536px) {
  .conatctModal .getintouch h3 {
    font-size: 18px;
  }
}
.conatctModal .getintouch h3 img {
  margin-right: 5px;
}
.conatctModal .getintouch p {
  font-size: 20px;
}
@media (max-width: 1536px) {
  .conatctModal .getintouch p {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .conatctModal .getintouch p {
    font-size: 16px;
  }
}
.conatctModal .getintouch p span {
  font-size: 15px;
}
@media (max-width: 1536px) {
  .conatctModal .getintouch p span {
    font-size: 14px;
  }
}
.conatctModal .modal-body {
  padding: 0;
}

@media (max-height: 880px) {
  .customScroll .view {
    max-height: 410px !important;
  }
  .customScroll .view.small {
    max-height: 280px !important;
  }
}

.formSec {
  flex-basis: 20%;
  padding: 1rem 2rem 1rem 3rem;
}
@media (max-width: 767px) {
  .formSec {
    flex-basis: 10%;
    padding: 1.5rem;
  }
}
.formSec .customScroll {
  overflow-x: hidden;
}
@media (max-height: 900px) {
  .formSec .customScroll {
    max-height: 380px !important;
  }
}
.formSec .customScroll form {
  margin-right: 1rem;
  padding-top: 10px;
}
.formSec .customScroll .mapSec {
  margin-right: 1rem;
}
.formSec .modal-header {
  margin-bottom: 20px;
}
.formSec .form-floating > .form-control-plaintext:not(:-moz-placeholder-shown), .formSec .form-floating > .form-control:not(:-moz-placeholder-shown) {
  top: -30px;
}
.formSec .form-floating > .form-control-plaintext:focus,
.formSec .form-floating > .form-control-plaintext:not(:placeholder-shown),
.formSec .form-floating > .form-control:focus,
.formSec .form-floating > .form-control:not(:placeholder-shown) {
  top: -30px;
}
.formSec .form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  transform: scale(0.85) translateY(-1.8rem) translateX(0.15rem);
}
.formSec .form-floating > .form-control-plaintext ~ label,
.formSec .form-floating > .form-control:focus ~ label,
.formSec .form-floating > .form-control:not(:placeholder-shown) ~ label,
.formSec .form-floating > .form-select ~ label {
  transform: scale(0.85) translateY(-1.8rem) translateX(0.15rem);
}
.formSec .form-floating > .form-control-plaintext:not(:-moz-placeholder-shown), .formSec .form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 0.75rem;
}
.formSec .form-floating > .form-control-plaintext:focus,
.formSec .form-floating > .form-control-plaintext:not(:placeholder-shown),
.formSec .form-floating > .form-control:focus,
.formSec .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 0.75rem;
}
.formSec .form-floating {
  margin-bottom: 30px;
}
.formSec .form-control {
  border-color: #dce2e6;
  border-radius: 10px;
}
.formSec .form-control:focus {
  box-shadow: none;
  outline: none;
  border-color: #333333;
}
.formSec textarea {
  height: 160px !important;
  resize: none;
}
@media (max-height: 900px) {
  .formSec textarea {
    height: 100px !important;
  }
}
.formSec label {
  font-size: 14px;
  color: #6f6f6f;
}

.thumbOuter {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.thumbOuter img {
  width: 100%;
}
@media (max-width: 767px) {
  .thumbOuter img {
    width: 100%;
    max-width: 300px;
    min-height: inherit !important;
  }
}
.thumbOuter .playIcon {
  position: absolute;
}

.modal-content {
  padding: 0 2rem 2rem;
}
@media (max-width: 1536px) {
  .modal-content {
    padding: 0 1.8rem 1.8rem;
  }
}
@media (max-width: 767px) {
  .modal-content {
    padding: 0;
  }
}
.modal-content .modal-header {
  padding-top: 2rem;
}

.faqPage .modal-content {
  background: url(../img/sponsord-background.png) no-repeat;
  background-size: cover;
}
.faqPage .modal-content {
  padding: 0 2.8rem 2.8rem;
}
@media (max-width: 1536px) {
  .faqPage .modal-content {
    padding: 0 2.4rem 2.4rem;
  }
}
@media (max-width: 767px) {
  .faqPage .modal-content {
    padding: 0 0.4rem 0.4rem;
  }
}
.faqPage .modal-content .modal-header {
  padding-top: 2rem;
}
.faqPage .accordion {
  margin-top: 30px;
  border: none;
  background-color: transparent;
}
.faqPage .accordion .accordion-item {
  border: none;
  border-top: 1px solid rgba(112, 112, 112, 0.8);
  border-radius: 0;
  background-color: transparent;
}
.faqPage .accordion .accordion-item .accordion-button {
  padding-left: 0;
  padding-right: 0;
  box-shadow: none;
  background-color: transparent;
}
.faqPage .accordion .accordion-item .accordion-header {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}
.faqPage .accordion .accordion-body {
  color: #4a5745;
  font-size: 14px;
  padding-left: 0;
  padding-right: 0;
}
.faqPage .accordion .accordion-button:focus {
  box-shadow: none;
  border-color: rgba(112, 112, 112, 0.8);
}
.faqPage .accordion .accordion-button:not(.collapsed) {
  background-color: transparent;
  color: #182115;
}

.sponsordModal .modal-content {
  background: url(../img/sponsord-background.png) no-repeat;
  background-size: cover;
}
.sponsordModal h2 {
  font-size: 24px;
  letter-spacing: 0.7px;
  margin-bottom: 30px;
}

.loginPage h2 {
  font-size: 24px;
  color: #386224;
  margin-top: 20px;
}
.loginPage .formSec {
  padding: 1rem 0;
}
.loginPage .modal-header,
.loginPage .modal-body {
  padding: 0;
}
.loginPage .modal-content {
  padding: 1.5rem;
}
.loginPage .nav-tabs {
  border-bottom: 6px solid rgba(232, 244, 226, 0.4);
  justify-content: space-around;
}
.loginPage .nav-tabs .nav-item {
  width: 50%;
  text-align: center;
}
.loginPage .nav-tabs .nav-link {
  border: none;
  color: #797979;
  font-size: 20px;
  width: 100%;
  position: relative;
}
.loginPage .nav-tabs .nav-link::after {
  width: 0;
  height: 6px;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -6px;
  content: "";
  display: block;
}
.loginPage .nav-tabs .nav-link.active {
  color: #386224;
}
.loginPage .nav-tabs .nav-link.active::after {
  width: 100%;
}
.loginPage .forgotPassword {
  display: flex;
  justify-content: flex-end;
}
.loginPage .forgotPassword a {
  color: #386224;
  font-size: 14px;
  text-decoration: underline;
}
.loginPage .send {
  width: 100%;
  margin-top: 20px;
}
.loginPage.modal {
  --bs-modal-width: 440px;
}

.aboutModal .btn-close {
  top: 10px;
  z-index: 11;
  right: 13px;
}
.aboutModal .aboutBanner {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .aboutModal .aboutBanner {
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 0px;
  }
}
.aboutModal .modal-content {
  padding: 0 1.1rem 2.1rem;
  background: url(../img/sponsord-background.png) no-repeat;
  background-size: cover;
}
@media (max-width: 767px) {
  .aboutModal .modal-content {
    padding: 0;
  }
}
.aboutModal p {
  margin-bottom: 20px;
  color: #4a5745;
  font-size: 16px;
}
@media (max-width: 1536px) {
  .aboutModal p {
    font-size: 13px;
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .aboutModal p {
    font-size: 14px;
  }
}
.aboutModal .about-text {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid #bedeaf;
  border-radius: 20px;
  padding: 50px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .aboutModal .about-text {
    padding: 10px;
  }
}
.aboutModal .customScroll > div {
  overflow-x: hidden !important;
}
.aboutModal .seasonWinners {
  background: url(../img/seasion-banner-bg.jpg) no-repeat;
  background-size: cover;
  padding: 5rem 0 10rem;
  border-radius: 20px;
}
.aboutModal .seasonWinners h2 {
  color: #fff;
  text-align: center;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .aboutModal .seasonWinners h2 {
    font-size: 20px;
  }
}
.aboutModal .playIcon {
  position: relative;
  margin: 50px 0;
}
.aboutModal .video-play-button {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 22px;
  height: 34px;
  /* background: #fa183d; */
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
}
.aboutModal .video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  border: 2px solid #459345;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}
.aboutModal .video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: #459345;
  border: 1px solid #459345;
  border-radius: 50%;
  transition: all 200ms;
}
.aboutModal .video-play-button:hover:after {
  background-color: #357035;
}
.aboutModal .video-play-button img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}
.aboutModal .video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 24px solid #fff;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
}
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
.aboutModal .followUsOn {
  padding: 20px;
  color: #fff;
  border-radius: 10px;
  margin-top: 30px;
  display: flex;
  width: 100%;
  position: relative;
}
@media (max-width: 1536px) {
  .aboutModal .followUsOn {
    align-items: center;
  }
}
.aboutModal .followUsOn::after {
  content: "";
  display: block;
  background: url(../img/dots-social.svg) no-repeat;
  background-position: right 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.aboutModal .followUsOn div {
  position: relative;
  z-index: 1;
}
.aboutModal .followUsOn .socailIcons {
  background: #fffaff;
  border-radius: 10px;
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  margin-right: 30px;
}
@media (max-width: 1536px) {
  .aboutModal .followUsOn .socailIcons {
    width: 3rem;
    height: 3rem;
    margin-right: 10px;
  }
  .aboutModal .followUsOn .socailIcons img {
    max-width: 80%;
    max-height: 90%;
  }
}
.aboutModal .followUsOn .count {
  font-size: 42px;
  font-weight: 600;
}
.aboutModal .followUsOn p {
  color: #fff;
  margin-bottom: 0;
}
@media (max-width: 990px) {
  .aboutModal .followUsOn p {
    font-size: 12px;
  }
}
@media (max-width: 990px) {
  .aboutModal .p-3 {
    padding: 0 !important;
  }
}

.teamStanding {
  background: url(../img/team-standing-bg.jpg) no-repeat 0 0;
  background-size: cover;
  padding: 30px;
  border-radius: 20px;
  margin-top: 30px;
}
.teamStanding .title {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
  margin-bottom: 30px;
}
.teamStanding .seasonPostion {
  border: 2px solid rgba(255, 255, 255, 0.1921568627);
  border-radius: 10px 60px 10px 50px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2.4rem;
}
@media (max-width: 767px) {
  .teamStanding .seasonPostion {
    margin-bottom: 20px;
  }
}
.teamStanding .seasonPostion h3 {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.75px;
  opacity: 0.9;
  margin-top: 20px;
  position: relative;
  margin-bottom: 30px;
}
.teamStanding .seasonPostion h3::after {
  content: "";
  display: block;
  width: 40px;
  height: 5px;
  margin: 0 auto;
  position: absolute;
  background: #fff;
  bottom: -12px;
  left: 0;
  right: 0;
  border-radius: 30px;
  opacity: 0.9;
}
.teamStanding .seasonPostion p {
  font-size: 19px;
  letter-spacing: 0.47px;
  color: #ffffff;
  opacity: 0.9;
}

.gallerySeasoModal .nav-tabs {
  border: none;
  justify-content: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  max-width: 100%;
  overflow-y: hidden;
  min-height: 60px;
}
@media (max-width: 767px) {
  .gallerySeasoModal .nav-tabs {
    overflow: auto hidden;
  }
}
.gallerySeasoModal .nav-tabs .nav-item {
  padding: 0 12px;
}
@media (max-width: 767px) {
  .gallerySeasoModal .nav-tabs .nav-item {
    padding: 0 9px;
  }
}
.gallerySeasoModal .nav-tabs .nav-item:first-child {
  padding-left: 0;
}
.gallerySeasoModal .nav-tabs .nav-link {
  border: none;
  border: 1px solid #386224;
  border-radius: 4px;
  color: #386224;
  position: relative;
  font-size: 1.25rem;
  font-weight: 500;
  white-space: nowrap;
}
@media (max-width: 767px) {
  .gallerySeasoModal .nav-tabs .nav-link {
    font-size: 1rem;
  }
}
.gallerySeasoModal .nav-tabs .nav-link.active {
  color: #fff;
  border-color: transparent;
}
.gallerySeasoModal .nav-tabs .nav-link.active:after, .gallerySeasoModal .nav-tabs .nav-link.active:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.gallerySeasoModal .nav-tabs .nav-link.active:after {
  border-color: rgba(88, 120, 65, 0);
  border-top-color: #587841;
  border-width: 9px;
  margin-left: -15px;
}
.gallerySeasoModal .season-list-item {
  position: relative;
  margin-bottom: 30px;
}
.gallerySeasoModal .season-list-item .matchimage {
  width: 100%;
  height: 250px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
}
.gallerySeasoModal .season-list-item .overlayList {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 1rem;
  z-index: 1;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
}
.gallerySeasoModal .season-list-item .overlayList h3 {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.57px;
  text-align: center;
}
.gallerySeasoModal .season-list-item .overlayList .view {
  border: 1px solid rgba(255, 255, 255, 0.1843137255);
  border-radius: 55px;
  color: #fff;
  min-width: 110px;
  text-align: center;
  padding: 3px 10px;
  margin-top: 20px;
}
.gallerySeasoModal .season-list-item .overlayList .view.icon {
  background: none;
  padding: 0;
  border: none;
  min-width: inherit;
}
.gallerySeasoModal .season-list-item:hover .overlayList {
  opacity: 1;
}
.gallerySeasoModal .season-list-item .matchNumb {
  position: absolute;
  border: 1px solid #ffffff;
  border-radius: 0px 4px 4px 0px;
  top: 30px;
  color: #fff;
  z-index: 2;
  padding: 3px 10px;
  left: 0;
  font-size: 14px;
  letter-spacing: 0.35px;
}
.gallerySeasoModal .season-list-item.media-listing {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1019607843);
  border: 10px solid #ffffff;
  border-radius: 4px;
  background-color: #fff;
  opacity: 1;
}
.gallerySeasoModal .season-list-item.digital {
  border-radius: 20px;
  padding-bottom: 10px;
}
.gallerySeasoModal .season-list-item.digital .matchimage {
  min-height: inherit;
  border-radius: 15px;
}
.gallerySeasoModal .season-list-item.digital h4 {
  font-size: 19px;
  display: flex;
  justify-content: space-between;
  color: #182115;
  padding: 15px 0;
  min-height: 78px;
  flex-wrap: wrap;
}
.gallerySeasoModal .season-list-item.digital h4 span {
  color: #4a5746;
  font-size: 14px;
  font-weight: normal;
}
@media (max-width: 767px) {
  .gallerySeasoModal .season-list-item.digital h4 span {
    display: block;
    padding-top: 10px;
  }
}
.gallerySeasoModal .season-list-item.digital .readMore {
  color: #3a6426;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 20px;
}
.gallerySeasoModal .season-list-item.digital p {
  color: #4a5745;
  font-size: 16px;
  margin-bottom: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  min-height: 72px;
}
@media (max-width: 767px) {
  .gallerySeasoModal .season-list-item.digital p {
    -webkit-line-clamp: inherit;
  }
}

.errorText {
  color: red;
  font-size: 12px;
}

.form-control.is-valid, .form-control.is-invalid {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
  background-position: right calc(0.375em + 0.1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
}
.form-control.is-invalid {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
}

.galleryPageOuter .hotspots .hotspot-item {
  min-width: 5%;
  min-height: 16%;
  cursor: pointer;
}
.galleryPageOuter .hotspots .hotspot-item .pulsating-circle {
  right: 0;
  margin: 0 auto;
  left: 0;
  width: 14px;
  height: 14px;
}
.galleryPageOuter .hotspots .hotspot-item a {
  background: none;
  border: none;
}
.galleryPageOuter .hotspots .hotspot-item a .arrow_box {
  display: none;
}
@media (max-width: 1800px) {
  .galleryPageOuter .hotspots .hotspot-item a {
    min-width: 56px;
  }
}
@media (max-width: 1536px) {
  .galleryPageOuter .hotspots .hotspot-item a {
    min-width: 50px;
  }
}
.galleryPageOuter .hotspots .hotspot-item.hotspot-item-1 {
  left: 18.8%;
  top: 45.5%;
}
.galleryPageOuter .hotspots .hotspot-item.hotspot-item-2 {
  left: 24.8%;
  top: 45.5%;
}
.galleryPageOuter .hotspots .hotspot-item.hotspot-item-3 {
  left: 30.8%;
  top: 45.5%;
}
.galleryPageOuter .hotspots .hotspot-item.hotspot-item-4 {
  left: 36.8%;
  top: 45.5%;
}
.galleryPageOuter .hotspots .hotspot-item.hotspot-item-5 {
  left: 42.8%;
  top: 45.5%;
}
.galleryPageOuter .hotspots .hotspot-item.hotspot-item-6 {
  left: 48.5%;
  top: 45.5%;
}
.galleryPageOuter .hotspots .hotspot-item.hotspot-item-7 {
  left: 54.3%;
  top: 45.5%;
}
.galleryPageOuter .hotspots .hotspot-item.hotspot-item-8 {
  left: 60.3%;
  top: 45.5%;
}
.galleryPageOuter .hotspots .hotspot-item.hotspot-item-9 {
  left: 65.8%;
  top: 45.5%;
}
.galleryPageOuter .hotspots .hotspot-item.hotspot-item-10 {
  left: 72%;
  top: 45.5%;
}
.galleryPageOuter .hotspots .hotspot-item.hotspot-item-11 {
  left: 77.8%;
  top: 45.5%;
}
.galleryPageOuter .hotspots .hotspot-item.hotspot-item-12 {
  left: 14.8%;
  top: 71.9%;
  min-width: 12.2%;
  min-height: 9.8%;
}
.galleryPageOuter .hotspots .hotspot-item.hotspot-item-12 .pulsating-circle {
  top: 13%;
  left: -72%;
}
.galleryPageOuter .hotspots .hotspot-item.hotspot-item-13 {
  left: 33.4%;
  top: 74.2%;
  min-width: 10.1%;
  min-height: 8%;
}
.galleryPageOuter .hotspots .hotspot-item.hotspot-item-13 .pulsating-circle {
  left: -53.8%;
  top: -10%;
}
.galleryPageOuter .hotspots .hotspot-item.hotspot-item-14 {
  left: 45.2%;
  top: 64.9%;
  min-width: 10%;
  min-height: 4%;
}
.galleryPageOuter .hotspots .hotspot-item.hotspot-item-14 .pulsating-circle {
  left: -65%;
  top: 49%;
}
.galleryPageOuter .hotspots .hotspot-item.hotspot-item-15 {
  left: 57%;
  top: 69.2%;
  min-width: 9.2%;
  min-height: 12.6%;
}
.galleryPageOuter .hotspots .hotspot-item.hotspot-item-15 .pulsating-circle {
  left: -47%;
  top: 35%;
}
.galleryPageOuter .hotspots .hotspot-item.hotspot-item-16 {
  left: 73.8%;
  top: 70.2%;
  min-width: 12.2%;
  min-height: 11.6%;
}
.galleryPageOuter .hotspots .hotspot-item.hotspot-item-16 .pulsating-circle {
  left: 80%;
  top: 65%;
}

.newsEventPageOuter .hotspot-item {
  width: 16%;
  height: 16%;
  cursor: pointer;
}
.newsEventPageOuter .hotspot-item a {
  background: no-repeat;
  border: none;
  height: 100%;
}
.newsEventPageOuter .hotspot-item a .pulsating-circle {
  width: 18px;
  height: 18px;
  left: 52.1%;
}
@media (max-width: 1536px) {
  .newsEventPageOuter .hotspot-item a .pulsating-circle {
    width: 12px;
    height: 12px;
  }
}
@media (max-width: 1800px) {
  .newsEventPageOuter .hotspot-item a {
    min-width: 56px;
  }
}
@media (max-width: 1536px) {
  .newsEventPageOuter .hotspot-item a {
    min-width: 50px;
  }
}
.newsEventPageOuter .hotspot-item.hotspot-item-1 {
  left: 33.5%;
  top: 38.7%;
}
@media (max-width: 1660px) {
  .newsEventPageOuter .hotspot-item.hotspot-item-1 {
    top: 39%;
  }
}
.newsEventPageOuter .hotspot-item.hotspot-item-2 {
  left: 50.8%;
  top: 38.7%;
}
@media (max-width: 1660px) {
  .newsEventPageOuter .hotspot-item.hotspot-item-2 {
    top: 39%;
  }
}

.membersLoungePage .hotspots .hotspot-item a {
  background: no-repeat;
  border: none;
  height: 100%;
}
.membersLoungePage .hotspots .hotspot-item a .pulsating-circle {
  width: 18px;
  height: 18px;
  left: 50%;
}
@media (max-width: 1536px) {
  .membersLoungePage .hotspots .hotspot-item a .pulsating-circle {
    width: 12px;
    height: 12px;
  }
}
@media (max-width: 1800px) {
  .membersLoungePage .hotspots .hotspot-item a {
    min-width: 56px;
  }
}
@media (max-width: 1536px) {
  .membersLoungePage .hotspots .hotspot-item a {
    min-width: 50px;
  }
}
.membersLoungePage .hotspots .hotspot-item.hotspot-item-1 {
  top: 58.5%;
  left: 5%;
}
@media (max-width: 1660px) {
  .membersLoungePage .hotspots .hotspot-item.hotspot-item-1 {
    top: 58.5%;
    left: 4%;
  }
}
@media (max-width: 1440px) {
  .membersLoungePage .hotspots .hotspot-item.hotspot-item-1 {
    top: 61.6%;
    left: 4%;
  }
}
@media (max-width: 990px) {
  .membersLoungePage .hotspots .hotspot-item.hotspot-item-1 {
    top: 62%;
    left: 3%;
  }
}
.membersLoungePage .hotspots .hotspot-item.hotspot-item-2 {
  top: 57%;
  left: 13%;
}
@media (max-width: 1440px) {
  .membersLoungePage .hotspots .hotspot-item.hotspot-item-2 {
    top: 59%;
    left: 13%;
  }
}
@media (max-width: 990px) {
  .membersLoungePage .hotspots .hotspot-item.hotspot-item-2 {
    top: 60%;
    left: 12%;
  }
}
.membersLoungePage .hotspots .hotspot-item.hotspot-item-3 {
  top: 56%;
  left: 20.5%;
}
@media (max-width: 1660px) {
  .membersLoungePage .hotspots .hotspot-item.hotspot-item-3 {
    top: 55.5%;
    left: 19.5%;
  }
}
@media (max-width: 1440px) {
  .membersLoungePage .hotspots .hotspot-item.hotspot-item-3 {
    top: 57.5%;
    left: 19.5%;
  }
}
@media (max-width: 990px) {
  .membersLoungePage .hotspots .hotspot-item.hotspot-item-3 {
    top: 57.5%;
    left: 18.5%;
  }
}
.membersLoungePage .hotspots .hotspot-item.hotspot-item-4 {
  top: 55.3%;
  left: 25.5%;
}
@media (max-width: 1660px) {
  .membersLoungePage .hotspots .hotspot-item.hotspot-item-4 {
    top: 54.5%;
    left: 24.5%;
  }
}
@media (max-width: 1440px) {
  .membersLoungePage .hotspots .hotspot-item.hotspot-item-4 {
    top: 56.2%;
    left: 24.5%;
  }
}
@media (max-width: 990px) {
  .membersLoungePage .hotspots .hotspot-item.hotspot-item-4 {
    top: 56.2%;
    left: 23.5%;
  }
}
.membersLoungePage .hotspots .hotspot-item.hotspot-item-5 {
  top: 54.7%;
  left: 29.7%;
}
@media (max-width: 1660px) {
  .membersLoungePage .hotspots .hotspot-item.hotspot-item-5 {
    top: 53.3%;
    left: 28.7%;
  }
}
@media (max-width: 1440px) {
  .membersLoungePage .hotspots .hotspot-item.hotspot-item-5 {
    top: 55%;
    left: 28.7%;
  }
}
@media (max-width: 990px) {
  .membersLoungePage .hotspots .hotspot-item.hotspot-item-5 {
    top: 55%;
    left: 27.7%;
  }
}
.membersLoungePage .hotspots .hotspot-item.hotspot-item-6 {
  top: 57.5%;
  left: 60.3%;
  width: 7.4%;
  height: 9%;
}
@media (max-width: 1660px) {
  .membersLoungePage .hotspots .hotspot-item.hotspot-item-6 {
    top: 58%;
    left: 60.3%;
  }
}
.membersLoungePage .hotspots .hotspot-item.hotspot-item-7 {
  top: 62%;
  left: 68.3%;
  width: 7.4%;
  height: 11%;
}
@media (max-width: 1660px) {
  .membersLoungePage .hotspots .hotspot-item.hotspot-item-7 {
    top: 62%;
    left: 68.3%;
  }
}

.mediaOuter {
  padding: 10px;
  overflow-x: hidden;
}

.gallerySeasoModal .modal-content {
  background: url(../img/sponsord-background.png) no-repeat;
  background-size: cover;
}
.gallerySeasoModal .nav-tabs.statsTabs {
  justify-content: flex-start;
}
.gallerySeasoModal .filterSec {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 1rem;
}
@media (max-width: 767px) {
  .gallerySeasoModal .filterSec {
    position: relative;
    margin-bottom: 20px;
  }
}
.gallerySeasoModal .filterSec .form-group {
  margin-left: 15px;
}
.gallerySeasoModal .filterSec .form-group select {
  border-color: #386224;
  height: 50px;
  border-radius: 4px;
}
.gallerySeasoModal .nav-tabs .nav-link {
  background: #fff;
}
.gallerySeasoModal td {
  font-size: 20px;
  font-weight: 600;
  padding: 10px 20px;
}
@media (max-width: 767px) {
  .gallerySeasoModal td {
    font-size: 14px;
    padding: 5px 10px;
  }
}
.gallerySeasoModal thead {
  border-radius: 4px;
}
.gallerySeasoModal thead th {
  background: #960396;
  padding: 15px;
  color: #fff;
}
@media (max-width: 767px) {
  .gallerySeasoModal thead th {
    font-size: 14px;
  }
}
.gallerySeasoModal thead .table > :not(caption) > * > * {
  background: #960396;
}

@media (max-height: 880px) {
  .loungeModal .customScroll .view {
    max-height: 300px !important;
  }
}

.resultMatchs .resultTitle {
  color: #fff;
  padding: 8px;
  display: flex;
  justify-content: space-between;
}
.resultMatchs .resultTitle .matchNo {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 110px;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
  padding: 7px 10px;
}
@media (max-width: 575px) {
  .resultMatchs .resultTitle .matchNo {
    height: 30px;
    font-size: 11px;
  }
}
.resultMatchs .resultTitle .dayOuter {
  padding-left: 50px;
  position: relative;
}
@media (max-width: 575px) {
  .resultMatchs .resultTitle .dayOuter {
    padding-left: 35px;
  }
}
.resultMatchs .resultTitle .dayOuter .dateTitme {
  font-size: 12px;
}
.resultMatchs .resultTitle .dayOuter .dateTitme span {
  display: block;
}
.resultMatchs .resultTitle .date {
  position: absolute;
  left: 0;
  font-weight: 600;
  font-size: 34px;
  top: -6px;
}
@media (max-width: 575px) {
  .resultMatchs .resultTitle .date {
    font-size: 24px;
  }
}

.tab-select {
  background-color: transparent;
  color: #fff;
  border: none;
}

.resultMatchs {
  margin-bottom: 30px;
}

.matchesTeams {
  background: url(../img/fixture-card-bg.jpg) no-repeat;
  background-size: cover;
  padding-bottom: 15px;
  background-position: 0 30%;
}
.matchesTeams .matchBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: #fff;
  font-size: 26px;
  font-weight: 800;
  color: #fff;
}
.matchesTeams .matchBetween img {
  max-height: 80px;
}
@media (max-width: 575px) {
  .matchesTeams .matchBetween img {
    max-height: 55px;
  }
}
.matchesTeams .teamsName {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
@media (max-width: 575px) {
  .matchesTeams .teamsName {
    font-size: 16px;
  }
}
.matchesTeams .teamsName span {
  font-weight: 800;
  color: #1e864e;
}
.matchesTeams p {
  font-size: 14px;
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
}

.topPerformers h2 {
  font-size: 36px;
  margin-bottom: 4rem;
  text-align: center;
  color: #386224;
}
@media (max-width: 1536px) {
  .topPerformers h2 {
    margin-bottom: 1rem;
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .topPerformers h2 {
    font-size: 20px;
  }
}
.topPerformers .pointsTable {
  color: #fff;
  border-radius: 10px;
  margin: 0 40px;
}
@media (max-width: 767px) {
  .topPerformers .pointsTable {
    margin: 0;
  }
}
.topPerformers .pointsTable ul {
  list-style: none;
}
.topPerformers .pointsTable ul li {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px 10px;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}
.topPerformers .pointsTable ul li:last-child {
  border-bottom: none;
}
@media (max-width: 1536px) {
  .topPerformers .pointsTable ul li {
    font-size: 1rem;
  }
}
.topPerformers .pointsTable ul li span {
  font-size: 1.8rem;
}
@media (max-width: 1536px) {
  .topPerformers .pointsTable ul li span {
    font-size: 1.4rem;
  }
}
@media (max-width: 767px) {
  .topPerformers .pointsTable ul li span {
    font-size: 1rem;
  }
}
.topPerformers .performerPlayer {
  position: relative;
  text-align: center;
}
@media (max-width: 767px) {
  .topPerformers .performerPlayer {
    margin-bottom: 30px;
  }
}
.topPerformers .performerPlayer .plyerName {
  font-family: "Poppins", sans-serif;
  color: #fff;
  text-align: center;
  font-size: 18px;
  padding: 1rem;
}
@media (max-width: 1536px) {
  .topPerformers .performerPlayer .plyerName {
    font-size: 15px;
  }
}

.did-floating-label-content {
  position: relative;
  margin-bottom: 20px;
}

.did-floating-label {
  color: #1e4c82;
  font-size: 13px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 11px;
  padding: 0 5px;
  background: #fff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.did-floating-input,
.did-floating-select {
  font-size: 12px;
  display: block;
  width: 100%;
  height: 36px;
  padding: 0 20px;
  background: #fff;
  color: #323840;
  border: 1px solid #3d85d8;
  border-radius: 4px;
  box-sizing: border-box;
}
.did-floating-input:focus,
.did-floating-select:focus {
  outline: none;
}
.did-floating-input:focus ~ .did-floating-label,
.did-floating-select:focus ~ .did-floating-label {
  top: -8px;
  font-size: 13px;
}

select.did-floating-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.did-floating-select::-ms-expand {
  display: none;
}

.did-floating-input:not(:-moz-placeholder-shown) ~ .did-floating-label {
  top: -8px;
  font-size: 13px;
}

.did-floating-input:not(:placeholder-shown) ~ .did-floating-label {
  top: -8px;
  font-size: 13px;
}

.did-floating-select:not([value=""]):valid ~ .did-floating-label {
  top: -8px;
  font-size: 13px;
}

.did-floating-select[value=""]:focus ~ .did-floating-label {
  top: 11px;
  font-size: 13px;
}

.did-floating-select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
  background-position: right 15px top 50%;
  background-repeat: no-repeat;
}

.did-error-input .did-floating-input,
.did-error-input .did-floating-select {
  border: 2px solid #9d3b3b;
  color: #9d3b3b;
}
.did-error-input .did-floating-label {
  font-weight: 600;
  color: #9d3b3b;
}
.did-error-input .did-floating-select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%239d3b3b'/%3E%3C/svg%3E%0A");
}

.editProfilePage .modal-content {
  border-radius: 30px;
}
.editProfilePage .modal-content,
.editProfilePage .modal-body {
  padding: 0;
}
.editProfilePage .modal-header {
  padding: 0;
}
.editProfilePage .modal-header .modal-title {
  display: none;
}
.editProfilePage .scoreListingOuter {
  padding: 10px;
}
.editProfilePage .myScore {
  flex-basis: 55%;
  padding: 2rem;
  padding-right: 90px;
}
@media (max-width: 990px) {
  .editProfilePage .myScore {
    flex-basis: 100%;
    padding: 0rem;
  }
}
.editProfilePage .myScore h2 {
  color: #960396;
  font-size: 28px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
@media (max-width: 990px) {
  .editProfilePage .myScore h2 {
    font-size: 16px;
    padding: 10px;
    margin-bottom: 10px;
    max-width: 88%;
  }
}
.editProfilePage .scoreList {
  border-radius: 85px;
  padding: 5px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 16px;
  margin-bottom: 20px;
}
.editProfilePage .scoreList p {
  font-weight: 600;
}
@media (max-width: 990px) {
  .editProfilePage .scoreList p {
    max-width: 90%;
    margin: 0 auto;
    font-size: 14px;
    text-align: center;
  }
}
.editProfilePage .scoreList .userImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #fff;
}
.editProfilePage .scoreList .points {
  font-size: 26px;
  font-weight: 600;
  padding-right: 0.5rem;
}
.editProfilePage .editFormSec {
  background-color: #f1f3f4;
  flex-basis: 45%;
  border-radius: 20px;
  min-height: 100vh;
  max-width: 640px;
}
@media (max-width: 990px) {
  .editProfilePage .editFormSec {
    flex-basis: 75%;
  }
}
@media (max-width: 767px) {
  .editProfilePage .editFormSec {
    min-height: inherit;
  }
}
.editProfilePage .editFormSec .formSec {
  margin-top: 4rem;
  padding: 1rem;
}
@media (max-width: 1536px) {
  .editProfilePage .editFormSec .formSec {
    margin-top: 2rem;
  }
}
.editProfilePage .editFormSec .formSec .form-floating > .form-select {
  padding-top: 0.525rem;
}
.editProfilePage .editFormSec .formSec .form-select:focus {
  border-color: #333333;
  box-shadow: none;
}
.editProfilePage .editFormSec .formSec .form-control,
.editProfilePage .editFormSec .formSec .form-select {
  background-color: transparent;
  border-radius: 10px;
  color: #6f6f6f;
}
.editProfilePage .editFormSec .formSec .form-floating > label {
  padding-top: 1.2rem;
}
.editProfilePage .editFormSec .formSec .form-floating > .form-control:not(:-moz-placeholder-shown) ~ label::after {
  background-color: #f1f3f4;
}
.editProfilePage .editFormSec .formSec .form-floating > .form-control-plaintext ~ label::after,
.editProfilePage .editFormSec .formSec .form-floating > .form-control:focus ~ label::after,
.editProfilePage .editFormSec .formSec .form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.editProfilePage .editFormSec .formSec .form-floating > .form-select ~ label::after {
  background-color: #f1f3f4;
}
.editProfilePage .shapeBox {
  background: url(../img/green_box.svg) no-repeat;
  background-position: center;
  padding: 25px;
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 20px;
  min-height: 190px;
  align-items: flex-start;
}
@media (max-width: 767px) {
  .editProfilePage .shapeBox {
    border-radius: unset;
  }
}
.editProfilePage .shapeBox .headingMain {
  display: inline-flex;
  color: #426d2e;
  background: #fff;
  font-weight: bold;
}
.editProfilePage .shapeBox .headingMain::after, .editProfilePage .shapeBox .headingMain::before {
  background: url(../img/line-shape-white.svg) no-repeat 0 0;
}
.editProfilePage .profileImage {
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 2px solid #5b8847;
  background-color: #f1f3f4;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.editProfilePage .profileImage .editIcon {
  position: absolute;
  right: -8px;
  bottom: 50px;
  background: url(../img/edit-icon.png) no-repeat 0 0;
  background-position: center center;
  background-size: 60% auto;
  width: 30px;
  height: 30px;
  padding: 0;
  background-color: #3c6729;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .editProfilePage .customScroll .view.small {
    max-height: 350px !important;
  }
}

.editPageOuter {
  display: flex;
}
@media (max-width: 767px) {
  .editPageOuter {
    flex-direction: column;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .modal-content {
    padding: 0 0.2rem 0.2rem;
  }
  .modal-content .modal-header {
    padding-top: 1rem;
  }
  .membersLoungePage .hotspots .hotspot-item.hotspot-item-1 {
    top: 60%;
    left: 3%;
  }
  .membersLoungePage .hotspots .hotspot-item.hotspot-item-2 {
    top: 57.5%;
    left: 12%;
  }
  .membersLoungePage .hotspots .hotspot-item.hotspot-item-3 {
    top: 56%;
    left: 18.4%;
  }
  .membersLoungePage .hotspots .hotspot-item.hotspot-item-4 {
    top: 54.2%;
    left: 23.5%;
  }
  .membersLoungePage .hotspots .hotspot-item.hotspot-item-5 {
    top: 53.5%;
  }
  .conatctModal .getintouch:after,
  .conatctModal .getintouch:before {
    background-size: auto 80%;
  }
}
@media (max-width: 900px) {
  .customScroll .view {
    max-height: 180px !important;
  }
  .topUserListing {
    max-height: 50%;
    overflow-y: auto;
  }
}
@media (max-width: 575px) {
  .wrapper {
    overflow-y: auto;
  }
}
@media screen and (max-width: 575px) and (orientation: portrait) {
  .imageOuter {
    min-width: 900px;
  }
  .imageOuter img {
    aspect-ratio: 9/16;
  }
  .hotspots {
    min-width: 900px;
  }
}
@media (min-width: 767px) and (max-width: 1000px) {
  .modal {
    --bs-modal-width: 700px;
  }
  .topPerformers .pointsTable {
    margin: 0;
  }
  .topPerformers .performerPlayer .plyerName {
    font-size: 12px;
    padding: 0.5rem;
  }
  .topPerformers .pointsTable ul li span {
    font-size: 1.2rem;
  }
}
@media (max-width: 767px) {
  .conatctModal.modalPages .btn-close {
    right: 20px;
    top: 20px;
  }
}
.squadPage {
  background: url(../img/team-squad-bg.jpg) no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  width: 100%;
  overflow-x: hidden;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  .squadPage {
    padding: 7rem 0;
  }
}
.squadPage .slick-slide img {
  margin: 0 auto;
}
@media (min-width: 1537px) {
  .squadPage .slick-slide img {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .squadPage .slick-slide img {
    max-width: 296px;
  }
}
@media (max-width: 767px) {
  .squadPage .playerImg {
    max-width: inherit;
  }
}
.squadPage .playerCol {
  position: relative;
}
.squadPage .playerCol .playerDescp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0 0 0;
}
@media (max-width: 990px) {
  .squadPage .playerCol .playerDescp {
    padding-top: 6px;
  }
}
@media (max-width: 767px) {
  .squadPage .playerCol .playerDescp {
    padding-top: 10px;
  }
}
.squadPage .playerCol .playerDescp .playerName {
  background: #a21ea6;
  transform: skew(-355deg);
  max-width: 70%;
  margin: 0 auto;
  padding: 0.5rem 1rem;
  min-height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1536px) {
  .squadPage .playerCol .playerDescp .playerName {
    min-height: 4rem;
  }
}
@media (max-width: 990px) {
  .squadPage .playerCol .playerDescp .playerName {
    min-height: 3rem;
  }
}
@media (max-width: 767px) {
  .squadPage .playerCol .playerDescp .playerName {
    max-width: 200px;
    min-height: 62.06px;
  }
}
.squadPage .playerCol .playerDescp .playerName h3 {
  text-transform: uppercase;
  transform: skew(355deg);
  color: #fff;
  font-size: 1.4rem;
  text-align: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media (max-width: 1536px) {
  .squadPage .playerCol .playerDescp .playerName h3 {
    font-size: 1rem;
  }
}
@media (max-width: 990px) {
  .squadPage .playerCol .playerDescp .playerName h3 {
    font-size: 0.8rem;
  }
}
@media (max-width: 767px) {
  .squadPage .playerCol .playerDescp .playerName h3 {
    font-size: 1.2rem;
  }
}
.squadPage .playerCol .playerDescp .playerPostion {
  font-size: 1.2rem;
  color: #386224;
  text-align: center;
  font-weight: bold;
  padding-top: 10px;
  text-transform: uppercase;
}
@media (max-width: 1536px) {
  .squadPage .playerCol .playerDescp .playerPostion {
    padding-top: 5px;
  }
}
@media (max-width: 990px) {
  .squadPage .playerCol .playerDescp .playerPostion {
    font-size: 0.8rem;
  }
}
@media (max-width: 767px) {
  .squadPage .playerCol .playerDescp .playerPostion {
    font-size: 1.3rem;
  }
}
.squadPage .playerCol .playerDescp .playerPicture {
  padding-top: 3.5rem;
  display: block;
}
@media (max-width: 1980px) {
  .squadPage .playerCol .playerDescp .playerPicture {
    padding-top: 3.3rem;
  }
}
@media (max-width: 1880px) {
  .squadPage .playerCol .playerDescp .playerPicture {
    padding-top: 2.4rem;
  }
}
@media (max-width: 1536px) {
  .squadPage .playerCol .playerDescp .playerPicture {
    padding-top: 2.8rem;
  }
}
@media (max-width: 1436px) {
  .squadPage .playerCol .playerDescp .playerPicture {
    padding-top: 2.1rem;
  }
}
@media (max-width: 996px) {
  .squadPage .playerCol .playerDescp .playerPicture {
    padding-top: 3.6rem;
  }
}
@media (max-width: 845px) {
  .squadPage .playerCol .playerDescp .playerPicture {
    padding-top: 3.1rem;
  }
}
@media (max-width: 767px) {
  .squadPage .playerCol .playerDescp .playerPicture {
    padding-top: 2.2rem;
  }
}

.playerDetailsOuter {
  padding: 30px;
  display: flex;
  width: 100%;
  flex: 1;
}
@media (max-width: 1536px) {
  .playerDetailsOuter {
    padding: 0;
  }
}
@media (max-width: 800px) {
  .playerDetailsOuter {
    flex-direction: column;
  }
}
.playerDetailsOuter .playerInfo {
  max-width: 440px;
  min-width: 440px;
  padding: 30px;
  border-radius: 6px;
}
@media (max-width: 990px) {
  .playerDetailsOuter .playerInfo {
    min-width: 300px;
  }
}
@media (max-width: 800px) {
  .playerDetailsOuter .playerInfo {
    min-width: 100%;
  }
}
.playerDetailsOuter .playerInfo .playerImageSec {
  position: relative;
  display: block;
  text-align: center;
  margin-bottom: 40px;
}
.playerDetailsOuter .playerInfo .playerName {
  background: #fff;
  border-radius: 4px;
  display: inline-block;
  padding: 10px 20px;
  color: #aa3e97;
  min-width: 50%;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
}
.playerDetailsOuter .playerInfo .playerinformation {
  background-color: rgba(255, 255, 255, 0.19);
  margin-bottom: 10px;
  display: flex;
  border-radius: 4px;
  padding: 8px 15px;
  color: #fff;
  font-weight: 600;
  justify-content: space-between;
}
.playerDetailsOuter .playerStatsDetails {
  padding: 0 3rem;
  width: 100%;
}
@media (max-width: 1536px) {
  .playerDetailsOuter .playerStatsDetails {
    padding: 0 2rem;
  }
}
@media (max-width: 767px) {
  .playerDetailsOuter .playerStatsDetails {
    padding: 0;
  }
}
.playerDetailsOuter .playerStatsHeader {
  display: flex;
  position: relative;
  margin-bottom: 50px;
  justify-content: space-between;
}
@media (max-width: 990px) {
  .playerDetailsOuter .playerStatsHeader {
    justify-content: space-between;
    margin-top: 30px;
    align-items: center;
  }
  .playerDetailsOuter .playerStatsHeader .filterSec {
    margin-bottom: 0;
    right: 0;
  }
}
@media (max-width: 767px) {
  .playerDetailsOuter .playerStatsHeader {
    flex-direction: column;
  }
  .playerDetailsOuter .playerStatsHeader h1 {
    margin-bottom: 20px;
  }
}
.playerDetailsOuter .playerStatsHeader .titleText {
  font-size: 1.8rem;
  color: #960396;
  font-weight: 600;
}
@media (max-width: 767px) {
  .playerDetailsOuter .playerStatsHeader .titleText {
    font-size: 1.2rem;
  }
}
.playerDetailsOuter .blackheader {
  background: url(../img/black-pattern.jpg) no-repeat;
  background-size: cover;
  padding: 3rem 2rem 2rem 2rem;
  width: 100%;
  color: #fff;
  border-radius: 20px;
  font-size: 3.4rem;
  font-weight: 600;
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .playerDetailsOuter .blackheader {
    font-size: 2.8rem;
  }
}
.playerDetailsOuter .blackheader p {
  font-size: 18px;
  font-weight: normal;
}
.playerDetailsOuter .blackheader .bdrRight {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}
@media (max-width: 990px) {
  .playerDetailsOuter .blackheader .bdrRight {
    border-right: none;
    margin-bottom: 20px;
  }
}

.dataDetails {
  border: 1px solid #c9d8c3;
  border-radius: 10px;
  padding: 1.2rem 1rem;
  margin-top: 30px;
}
.dataDetails .attackingData {
  text-align: center;
  font-size: 1.8rem;
  color: #272727;
  font-weight: bold;
}
.dataDetails .attackingData p {
  font-size: 18px;
  font-weight: normal;
}
.dataDetails [class*=col-] {
  border-right: 1px solid rgba(126, 126, 126, 0.5);
}
@media (max-width: 767px) {
  .dataDetails [class*=col-] {
    border-right: none;
  }
}
.dataDetails [class*=col-]:last-child {
  border-right: none;
}

.tableOuter {
  background: #000000;
  border-radius: 5px;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.04), 0 1px 0 rgba(255, 255, 255, 0.15) inset;
  border: 1px solid #000000;
  background-clip: padding-box;
  max-width: 90%;
  margin: 0 auto;
  background-size: contain;
  background-position: center top;
  position: relative;
  padding: 10px;
}
@media (max-width: 1175px) {
  .tableOuter {
    max-width: 100%;
  }
}
@media (max-width: 575px) {
  .tableOuter {
    padding: 4px;
  }
}
.tableOuter .display-shine {
  position: absolute;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjEuMCIgeDI9IjAuNSIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwLjA1Ii8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==);
  background-size: 100%;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  width: 100%;
  height: 50%;
  pointer-events: none;
}
.tableOuter .display-monitor {
  position: absolute;
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAYAAACZgbYnAAAAEElEQVQIW2NkYGBQYwQSDAAA/QApT6IXSwAAAABJRU5ErkJggg==);
  width: 98.5%;
  height: 98.5%;
}
.tableOuter .display {
  background-color: rgba(35, 13, 80, 0.23);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.53);
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzFmMDkyNiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzEwMDQyNCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==);
  background-size: 100%;
  background-image: linear-gradient(to bottom, #fff, #efefef);
  margin: 10px;
  padding: 10px;
  text-align: center;
}
@media (max-width: 767px) {
  .tableOuter .display {
    overflow-x: auto;
  }
}
.tableOuter::after {
  content: "";
  top: 0;
}
.tableOuter .table-responsive {
  background-color: transparent;
  position: relative;
  z-index: 1;
}
.tableOuter .table-responsive td {
  background-color: transparent;
}
@media (max-width: 767px) {
  .tableOuter .table-responsive tr td:nth-child(2) {
    min-width: 150px;
  }
}

.topUserListing {
  background-color: #fff;
  min-width: 25%;
  position: absolute;
  left: 37.5%;
  top: 31.5%;
  border: 10px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #5e3308, #2a1301);
}
.topUserListing .top-leaderborad {
  background: #aa3e97;
  padding: 10px 0;
  margin: 0;
  font-weight: bold;
  color: #fff;
  font-size: 12px;
}
.topUserListing .top-leaderName {
  font-size: 11px;
  font-weight: 500;
  text-align: center;
}
.topUserListing .leaderborad-listing {
  background: #1e5341;
  margin: 0;
  color: #fff;
  padding: 10px 0;
  font-size: 12px;
}
.topUserListing .leaderborad-listing:nth-child(even) {
  background: #002a1c;
}
.topUserListing .leaderborad-listing img {
  height: auto;
  max-height: 30px;
  width: auto;
}
.topUserListing .leaderborad-listing .leaderpic,
.topUserListing .leaderborad-listing .leadername {
  display: inline-flex;
  align-items: center;
}
.topUserListing .leaderborad-listing .userListing {
  display: flex;
  width: 100%;
  align-items: center;
  font-weight: 500;
}
.topUserListing .leaderborad-listing .leadername2 {
  font-size: 16px;
  font-weight: 600;
}
.topUserListing .leaderborad-sr {
  position: relative;
  z-index: 9;
  color: #000;
  font-weight: bold;
  padding: 5px 0px;
}
.topUserListing .leaderborad-sr:after {
  position: absolute;
  left: -12px;
  top: -10px;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 34px 34px 0 0;
  border-color: #f8df25 transparent transparent transparent;
  z-index: -1;
}
@media (max-width: 1600px) {
  .topUserListing .leaderborad-sr:after {
    top: -5px;
    left: -17px;
  }
}
.topUserListing .leaderborad-sr .rankCount {
  position: absolute;
  top: -8px;
  left: -5px;
}
@media (max-width: 1600px) {
  .topUserListing .leaderborad-sr .rankCount {
    top: -3px;
    left: -10px;
  }
}

.quizSec {
  background: url(../img/quiz-bg.jpg) no-repeat;
  background-size: cover;
  position: relative;
  padding: 90px 70px;
  border-radius: 20px;
  min-height: 625px;
  max-width: 80%;
  margin: 0 auto;
  position: relative;
  margin-top: 8rem;
}
@media (max-width: 1536px) {
  .quizSec {
    min-height: inherit;
    padding-bottom: 20px;
    max-width: 100%;
    margin-top: 5rem;
  }
}
@media (max-width: 767px) {
  .quizSec {
    max-width: 100%;
    padding: 20px;
  }
}
.quizSec h2 {
  color: #fff;
  font-size: 2rem;
  margin-bottom: 4rem;
}
@media (max-width: 767px) {
  .quizSec h2 {
    font-size: 1.6rem;
  }
}
.quizSec .form-group {
  margin-bottom: 3rem;
}
.quizSec .form-group label {
  color: #fff;
}
.quizSec [type=radio]:checked,
.quizSec [type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.quizSec [type=radio]:checked + label,
.quizSec [type=radio]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px 28px 16px 48px;
  min-width: 300px;
  font-size: 1rem;
  border-radius: 60px;
}
@media (max-width: 767px) {
  .quizSec [type=radio]:checked + label,
  .quizSec [type=radio]:not(:checked) + label {
    min-width: 100%;
  }
}
.quizSec [type=radio]:checked + label:before,
.quizSec [type=radio]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 20px;
  top: 21px;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
}
.quizSec [type=radio]:checked + label:after,
.quizSec [type=radio]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #fff;
  position: absolute;
  top: 24px;
  left: 23px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.quizSec [type=radio]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.quizSec [type=radio]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
.quizSec [type=radio]:checked + label {
  background: transparent linear-gradient(270deg, #639250 0%, var(--unnamed-color-386224) 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(270deg, #639250 0%, #386224 100%) 0% 0% no-repeat padding-box;
}
.quizSec .text-right {
  text-align: ce;
}
.quizSec .manImage img {
  position: absolute;
  top: -15rem;
  max-height: 850px;
  right: 6rem;
}
@media (max-width: 1536px) {
  .quizSec .manImage img {
    max-height: 700px;
    right: 2rem;
    top: -10rem;
  }
}
@media (max-width: 990px) {
  .quizSec .manImage img {
    display: none;
  }
}

@media (max-width: 990px) {
  .pagination {
    background: #fff;
  }
}
@media (max-width: 767px) {
  .modalPages .btn-close {
    width: 24px;
    height: 24px;
  }
}
@media only screen and (max-width: 667px) and (orientation: landscape) {
  .framephotoboothOuter .framephotobooth {
    width: 100%;
  }
}
#warning-message {
  display: none;
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .main-wrapper,
  .container {
    display: none;
  }
  #warning-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  #warning-message div {
    text-align: center;
  }
  #warning-message p {
    font-weight: bold;
  }
}/*# sourceMappingURL=custom.css.map */